// import './App.css';
// import './assets/css/home.css';
// import React from 'react'
// import ReactDOM from 'react-dom'
// import { BrowserRouter } from 'react-router-dom'
// import App from './App'

// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('app')
// );


// import './App.css';
// import './assets/css/home.css';
// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
// import App from './App';

// const root = document.getElementById('app');
// const rootElement = React.createRoot(root);

// rootElement.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );

// Import necessary dependencies
import React from 'react';
import { createRoot } from 'react-dom/client'; // Import from "react-dom/client"
import { BrowserRouter } from 'react-router-dom';
import App from './App';

// Find the root element by ID
const rootElement = document.getElementById('app');

// Use createRoot to render your application
const root = createRoot(rootElement);

root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);

