import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import banImg from "../../Assets/Images/teamDetail.jpg";
import team from "../../Assets/Images/team/team3.jpg";
import education from "../../Assets/Images/team/education.jpg";
import experience from "../../Assets/Images/team/experience.jpg";
import experience2 from "../../Assets/Images/team/exp2.jpg";
import certified from "../../Assets/Images/certified.jpg";
import award from "../../Assets/Images/team/award.jpg";
import { TestimonialV2 } from "../../Components/TestimonialV2";
import { Loader } from "../../Components/Loader";

export const TeamDeatilShaista = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <section
        className={"inner-bnr banner-section"}
        style={{
          backgroundImage: `url(${banImg})`,
          backgroundPosition: "right",
          padding: "100px ",
        }}
      >
        <Container>
          <div className="banner_header">
            <h2 className="fw-bold mb-3 text-uppercase text-left">Team</h2>
          </div>
        </Container>
      </section>

      <section className="sec-padding">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={team} className="img-fluid" alt="team1img" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                {/* <h3>About us</h3> */}
                <h3>Shaista Peart</h3>
                <div className="ct_card_name mt-2">
                   BA (Hons), CIPPE
                </div>
                <div className="team-designation">
                Senior Data Protection & Privacy Professional | Healthcare Information Governance Specialist
                </div>
                <p>
                Shaista Peart is a Certified Information Privacy Professional with extensive knowledge and experience in data protection and privacy compliance. As a pragmatic strategist, her aim is to equip businesses with risk and compliance strategies necessary for effective corporate governance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                {/* <h3>About us</h3> */}
                <h3 className="mb-2">Work Experience </h3>
                <p>
                Data Protection Consultant Companies locally and internationally • March 2019 - Present
                </p>
                <ul>
                  <li>
                    Conduct GAP analysis to determine the level of current compliance with data protection legislation.
                  </li>
                  <li>Support with developing a corporate governance and compliance program.</li>
                  <li>Policy review and drafting, Template review and creation.</li>
                  <li>Deliver in-house training.</li>
                  <li>Provide support in completing data protection impact assessments.</li>
                  <li>Review data processing and sharing agreements.</li>

                </ul>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={education} className="img-fluid cover" alt="team2img" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience} className="img-fluid cover" alt="experienceimg" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h6 className="mb-2"><b>Interim Data Protection Officer University of Wolverhampton • June 2018 – September 2018 </b></h6>                
                <ul>
                <li>Provided specialist advice to staff and students regarding data protection queries and concerns.</li>
                <li>Developed and reviewed strategic documents such as governance frameworks, policies, and procedures to foster greater compliance with DP legislation.</li>
                <li>Developed and delivered staff training to promote a culture of data protection compliance.</li>
                <li>Ensured the effective co-ordination and completion of subject access requests to maintain the University’s compliance rate.</li>
                <li>Responsible for investigating data breaches and where necessary reporting to the Information Commissioner (ICO).</li>
                <li>Processed and reviewed third-party requests for information.</li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <ul>
                <li>Reviewed and negotiated data sharing and processing agreements with clients and suppliers.</li>
                <li>Collaborated with project managers to highlight privacy issues at the start of projects.</li>
                <li>Supported the completion of Data Protection Impact Assessments.</li>
                <li>Liaised with the information security team to implement security controls and processes that mitigate privacy risks.</li>
                <li>Reported data protection concerns to the University Secretary for escalation to the board.</li>
                <li>Managed all freedom of information requests received into the University and ensured deadlines were met.</li>
                </ul>
               
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience2} className="img-fluid cover" alt="experience2" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={award} className="img-fluid cover" alt="award" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h6 className="mb-2"><b>Information Governance Support Officer University Hospitals Birmingham • Nov 2016 – May 2018 </b></h6>

                <ul>
                <li>Provided specialist advice to staff regarding information sharing, consent, confidentiality, information security, and other privacy issues that may arise.</li>
                <li>Investigated data protection breaches and suggested appropriate recommendations and solutions.</li>
                <li>Developed annual audit plans and schedules to assess the hospital’s compliance with data protection.</li>
                <li>Processed and assisted staff with police requests for information.</li>
                <li>Ensured compliance with Freedom of Information Act, responding to requests and managing administrative requirements.</li>
                <li>Conducted and reviewed the hospital’s data mapping.</li>
                <li>Reviewed and improved the information asset register.</li>
                <li>Collated and reviewed evidence for the IG Toolkit.</li>
                <li>Investigated ICO subject access requests complaints and prepared reports of findings.</li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
           
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Certifications</h3>
                <ul>
                <li>International Association of Privacy Professionals (2019) Certified Information Privacy Professional (CIPPE)</li>
                <li>Chartered Management Institute (2015) Level 5 Diploma in Professional Consulting</li>
                </ul>

                <h3 className="mb-2">Academic Background</h3>
                <ul>
                <li>Birmingham City University (2012-2015) BA (Hons) Business and Management</li>
                <li>E-skills UK Apprenticeship (2011-2012) Advanced and Intermediate apprenticeship in IT, Software, Web, and Telecoms Professionals</li>
                <li>Sandwell College (2010-2011) Diploma in Performing Arts</li>
                <li>Hydel High School (Jamaica, 2008 - 2009) High School Diploma</li>

                </ul>
                <h3 className="mb-2 mt-2">Hobbies and Interests</h3>
                <ul>
                  <li>Performing Arts, Playing the keyboard and drum, Group singing.</li>
                </ul>
              </div>
            </div>

            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={certified} className="img-fluid cover" alt="certified" />
            </div>
          </div>
        </div>
      </section>

      <div className="bg-light">
        <TestimonialV2 />
      </div>
    </>
  );
};
