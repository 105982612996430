import React from 'react'
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export const PageMetaTitle = ({data}) => {
  const location = useLocation();
   const {pageTitle,pageDescription} = data
   const WebUrl = location.href
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content="URL to your Open Graph image" />
      <meta property="og:type" content='WebPage' />
      <meta property="og:url" content={WebUrl} />
      <link rel="canonical" href={WebUrl} />
      <link rel="shortlink"  href={WebUrl} />
    </Helmet>
  )
}
