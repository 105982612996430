import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import banImg from "../../Assets/Images/teamDetail.jpg";
import team from "../../Assets/Images/team/team5.jpg";
import education from "../../Assets/Images/team/education.jpg";
import experience from "../../Assets/Images/team/experience.jpg";
import experience2 from "../../Assets/Images/team/exp2.jpg";
import teamSimon from "../../Assets/Images/teamSimon.jpg";
import teamSimonChess from "../../Assets/Images/teamSimonChess.jpg";
import teamSimonCC from "../../Assets/Images/teamSimonCC.jpg";
import teamSimonIT from "../../Assets/Images/teamSimonIT.jpg";
import microsoft from "../../Assets/Images/microsoft.jpg";
import teamSolution from "../../Assets/Images/teamSolution.jpg";
import { TestimonialV2 } from "../../Components/TestimonialV2";
import { Loader } from "../../Components/Loader";

export const TeamDeatilSimonBliss = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <section
        className={"inner-bnr banner-section"}
        style={{
          backgroundImage: `url(${banImg})`,
          backgroundPosition: "right",
          padding: "100px ",
        }}
      >
        <Container>
          <div className="banner_header">
            <h2 className="fw-bold mb-3 text-uppercase text-left">Team</h2>
          </div>
        </Container>
      </section>

      <section className="sec-padding">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={team} className="img-fluid" alt="teamimg" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                {/* <h3>About us</h3> */}
                <h3>Simon Bliss</h3>
                <div className="ct_card_name mt-2">
                  Enterprise Architect | IT Management | Technical Seller
                </div>               
                <p>
                Simon Bliss is a personable, confident, and technically proficient individual with a career spanning 32 years across diverse environments. He has excelled in various roles in software sales, technical strategic selling, consulting, enterprise architecture, and head of consulting, gaining extensive experience in business development, solution architecture, project and program management, and team leadership.
                </p>
                <p>
                Simon is known for his reliability, enthusiasm, and articulate communication skills. He possesses a positive attitude towards account management, business development, proposition development, solutions, technologies, and fostering productive relationships between teams and client decision-makers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Skills & Experience</h3>
                <ul>
                <li>Leadership, proposition development, sales, and business development</li>
                <li>Department management</li>
                <li>Business and IT strategy and enterprise architecture</li>
                <li>Engaging with board-level and business decision-makers</li>
                <li>Expertise in the finance industry</li>
                </ul>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={education} className="img-fluid cover" alt="education1img" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience} className="img-fluid cover" alt="experienceimg" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Technical Capabilities & Focus </h3>
                
                <ul>
                  <li>Enterprise and solution architecture</li>
                  <li>Business strategy and transformation</li>
                  <li>Cloud solutions and managed services</li>
                </ul>
                <p>Simon's career journey has encompassed various roles and experiences, including managing target-holding teams, driving business development, shaping engagements, defining commercial approaches, and ensuring successful project delivery within IT software and solutions selling and IT consulting professional services.</p>
                <p>Simon's entrepreneurial spirit led him to venture into multiple technical start-up businesses, providing valuable lessons in resilience, adaptability, and problem-solving. These ventures have given him a unique client-side perspective on technology, cloud computing, and software in start-up companies.</p>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Career Timeline</h3>
                <p className="mb-2">Entrepreneur 2018 – Present</p>
                <h3>Microsoft - Technology Strategist 2012–2018</h3>
                <ul>
                <li>Played a key role in the enterprise sales account team at Microsoft.</li>
                <li>Responsible for maintaining key C-suite relationships with CIO, CTO, and CISO.</li>
                <li>Focused on understanding industry and customer business and technology strategies.</li>
                <li>Achieved exceptional results, consistently closing strategic sales and revenue growth objectives.</li>
                <li>Awarded the Circle of Excellence Gold Club Winner in 2015.</li>
                </ul>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience2} className="img-fluid cover" alt="experience2img" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={teamSimon} className="img-fluid cover" alt="teamSimon" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Senior Account Executive 2015-2017 </h3>

                <ul>
                <li>Managed all commercial and sales aspects of the enterprise finance team at Microsoft.</li>
                <li>Oversaw forecasting, sales management, and more.</li>
                <li>Leveraged sales experience and technical/architectural capabilities to excel in the role.</li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Dimension Data | Microsoft Manager 2010-2012 </h3>

                <ul>
                <li>Held the position of Microsoft solutions manager at Dimension Data.</li>
                <li>Managed various functions in the UK Microsoft line-of-business.</li>
                <li>Played a role in business development, services proposition development, practice management, and more.</li>
                <li>Led the solution architect team for complex projects.</li>
                <li>Collaborated with Microsoft for joint selling.</li>
                </ul>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={teamSimonChess} className="img-fluid cover" alt="teamSimonChess" />
            </div>
          </div>
        </div>
      </section>


      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={teamSimonCC} className="img-fluid cover" alt="teamSimonCC" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">C&C Consulting | Enterprise Architect 2008-2010</h3>

                <ul>
                <li>Provided enterprise architecture, consulting, and solution architecture services for BAA, the largest client.</li>
                <li>Consulted on IT sourcing, sourcing strategy, IT services engagements, IT organizations, and contracts.</li>
                <li>Led technology transformation at BAA's Terminal 2 project.</li>
                <li>Named the lead IT enterprise architect for the BAA IT capital program (£2+ billion).</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>


      
      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Allegis Group | Head of IT Services 2007-2008 </h3>

                <ul>
                <li>Led, developed, and managed all aspects of the IT Services business.</li>
                <li>Focused on business development, customer relationship development, and sales strategy definition.</li>
                <li>Defined technical propositions and delivered high-quality managed services.</li>

                </ul>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={teamSimonIT} className="img-fluid cover" alt="teamSimonIT" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={microsoft} className="img-fluid cover" alt="microsoft" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Teksys | Consulting Services Manager 2002-2007</h3>

                <ul>
                <li>Managed the entire consulting function within Teksys, a Microsoft Gold partner.</li>
                <li>Oversaw a team of infrastructure consultants and project managers.</li>
                <li>Defined and managed consulting functions, company services propositions, pre-sales activities, client engagement approaches, project management functions, commercial planning, and financial reporting.</li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Henderson Global Investors | Architect 1999-2002</h3>

                <ul>
                <li>Served as a solution architect and technical project manager for infrastructure projects related to the bank's e-business initiatives.</li>
                
                </ul>
                <p>Simon Bliss's journey in the professional world reflects his dedication, adaptability, and technical prowess. He has consistently exceeded expectations and delivered impactful results throughout his career.</p>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={teamSolution} className="img-fluid cover" alt="teamSolution" />
            </div>
          </div>
        </div>
      </section>

      <div className="bg-light">
        <TestimonialV2 />
      </div>
    </>
  );
};
