import React from "react";
import Banner from "../Components/Banner";
import { Container } from "react-bootstrap";
import indus1 from "../Assets/Images/indus1.png";
import indus2 from "../Assets/Images/indus2.png";
import indus3 from "../Assets/Images/indus3.png";
import indus4 from "../Assets/Images/indus4.png";
import indus5 from "../Assets/Images/indus5.png";
import indus6 from "../Assets/Images/indus6.png";
import image1 from "../Assets/Images/image58.png";
import image2 from "../Assets/Images/image59.png";
import image3 from "../Assets/Images/image60.png";
import image4 from "../Assets/Images/image63.png";
import image5 from "../Assets/Images/image64.png";
import indusBan from "../Assets/Images/industriesBan.png";
import FAQ from "../Components/FAQ";
import ServiceSlider from "../Components/ServiceSlider";

const Industries = () => {
  var bannerContent = {
    title1: "Biomedical & Pharmaceutical",
    title2: "sample industry.",
    cardTitle:
      "Simplified advice and solutions. Engaging training. Industry approved.",
    cardPara:
      "Embed data protection, privacy, quality, security, and safety by design and default. Embed data protection, privacy, quality, security, default.",
  };
  return (
    <>
      <Banner bannerCnt={bannerContent} banImg={indusBan} />
      <section className="our-expertise-sec sec-padding">
        <Container>
          <h2 className="sec-heading">Our Expertise</h2>
          <div className="our-expertise-cards sec-padding-bottom">
            <div className="our-expertise-card">
              <img  loading="lazy"  width="" height=""   src={indus1} alt="indus1" />
              <p>Cyber Security Audit and Review Services</p>
            </div>
            <div className="our-expertise-card">
              <img  loading="lazy"  width="" height=""   src={indus2} alt="indus2" />
              <p>Clinical Safety Officer (CSO) Services</p>
            </div>
            <div className="our-expertise-card">
              <img  loading="lazy"  width="" height=""   src={indus3} alt="indus3" />
              <p>Data Protection Officer (DPO) Services</p>
            </div>
            <div className="our-expertise-card">
              <img  loading="lazy"  width="" height=""   src={indus4} alt="indus4" />
              <p>Data Privacy and GDPR Consultancy Services</p>
            </div>
            <div className="our-expertise-card">
              <img  loading="lazy"  width="" height=""   src={indus5} alt="indus5" />
              <p>ISO 27001 Audit and Certification Services</p>
            </div>
            <div className="our-expertise-card">
              <img  loading="lazy"  width="" height=""   src={indus6} alt="indus6" />
              <p>Virtual Chief Information Security Officer Services (VCISO)</p>
            </div>
          </div>
          <ul className="industries-list">
            <li>ALL</li>
            <li>AUTOMOTIVE</li>
            <li>B2B</li>
            <li>ECOMMERCE</li>
            <li>EDUCATION</li>
            <li>HEALTH</li>
            <li>MANUFACTURING</li>
            <li>PROFESSIONAL AERVICES</li>
          </ul>
        </Container>
      </section>
      <ServiceSlider />
      <section className="industries-services sec-padding-bottom">
        <Container>
          <div className="service-cards">
            <div className="service-card">
              <img  loading="lazy"  width="" height=""   alt="image1" src={image1} />
              <h5>Integrated Marketing</h5>
              <p>
                An integrated search strategy, sharing data and process between
                each discipline, resulting in a greater ROI.
              </p>
            </div>
            <div className="service-card">
              <img  loading="lazy"  width="" height=""   alt="image2" src={image2} />
              <h5>Medical PPC</h5>
              <p>
                An award-winning Medical PPC campaign for a top rated Pharmacy,
                generating a significant return.
              </p>
            </div>
            <div className="service-card">
              <img  loading="lazy"  width="" height=""   alt="image3" src={image3} />
              <h5>Medical SEO</h5>
              <p>
                An award winning Medical SEO campaign for a top health
                information site, turbocharging organic traffic.
              </p>
            </div>
            <div className="service-card">
              <img  loading="lazy"  width="" height=""   alt="image4" src={image4} />
              <h5>Healthcare Website</h5>
              <p>
                An award-winning Medical PPC campaign for a top rated Pharmacy,
                generating a significant return.
              </p>
            </div>
            <div className="service-card">
              <img  loading="lazy"  width="" height=""   alt="image5" src={image5} />
              <h5>Charity Web Design</h5>
              <p>
                An award winning Medical SEO campaign for a top health
                information site, turbocharging organic traffic.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <FAQ />
    </>
  );
};

export default Industries;
