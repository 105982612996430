import React from "react";
import { Container } from "react-bootstrap";
import Banner from "../../../Components/Banner";
import SubmitRFP from "../../../Components/SubmitRFP";
import { PRIVACY_INFORMATION_MANAGMENT_SYSTEM } from "../../../services/Url";
import { useCustomGet } from "../../../services/Service";
import { TestimonialV2 } from "../../../Components/TestimonialV2";
import { Loader } from "../../../Components/Loader";
import ServiceSlider from "../../../Components/ServiceSlider";

const PrivacyInformationManagementSystem = () => {
  const { data, isLoading } = useCustomGet(PRIVACY_INFORMATION_MANAGMENT_SYSTEM);

  var bannerContent = {
    title: `<h1>${data?.pims_banner_heading}</h1`,
    cardTitle:data?.pims_banner_sub_heading,
    cardPara:data?.pims_banner_content,
  }

  return (
    <>
      {
        isLoading ? 
        <Loader /> :''
      }
      <Banner bannerCnt={bannerContent} banImg={data?.pims_banner_image} />
      <section className=" bg-light service-trusted-by">
          <Container>
           <ServiceSlider />
           </Container>
        </section>
      <div className="internal-service-page" id="record-mang-service">
      
        <section
          className="award-sec sec-padding-top"
          style={{ backgroundColor: "#fff" }}
        >
          <Container>
            <h2 className="sec-heading">
              {data?.pims_implementation_heading}
            </h2>
            <div className="complex-chng-des">
              <div className="cmpx-img-card mt-0 img_design">
                <img  loading="lazy"  width="" height=""   src={data?.pims_implementation_image} alt="pims_implementation_image" />
              </div>
              <div className="cmpx-chng-des ">
                <div className="ltd-case-description mt-md-4 mt-3">
                  <div className="custom-list"
                    dangerouslySetInnerHTML={{__html: data?.pims_implementation_content}}
                  >             
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section
          className="award-sec sec-padding-top"
          style={{ backgroundColor: "#fff" }}
        >
          <Container>
            <h2 className="sec-heading">
              {data?.pims_approach_heading}
            </h2>
            <div className="complex-chng-des reverse">
              <div className="cmpx-chng-des ">               
                <div className="ltd-case-description mt-md-4 mt-3">
                  <div className="custom-list"
                    dangerouslySetInnerHTML={{__html: data?.pims_approach_content}}
                  >                   
                  </div>
                </div>
              </div>
              <div className="cmpx-img-card mt-0  img_design">
                <img  loading="lazy"  width="" height=""   src={data?.pims_approach_image} alt="pims_approach_image" />
              </div>
            </div>
          </Container>
        </section>
        <section
          className="award-sec sec-padding"
          style={{ backgroundColor: "#fff" }}
        >
          <Container>
            <h2 className="sec-heading">
                {data?.pims_benifit_heading}
            </h2>
            <div className="complex-chng-des">
              <div className="cmpx-img-card mt-0  img_design">
                <img  loading="lazy"  width="" height=""   src={data?.pims_benifit_image} alt="pims_benifit_image" />
              </div>
              <div className="cmpx-chng-des custom-list "
                dangerouslySetInnerHTML={{__html: data?.pims_benifit_content}}
              >
              </div>
            </div>
          </Container>
        </section>
        <section
          className="award-sec sec-padding-bottom"
          style={{ backgroundColor: "#fff" }}
        >
          <Container>
            <h2 className="sec-heading">
              {data?.pims_journey_heading}
            </h2>
            <div className="complex-chng-des reverse">
              <div className="cmpx-chng-des custom-list"
                dangerouslySetInnerHTML={{__html:data?.pims_journey_content}}
              >                               
              </div>
              <div className="cmpx-img-card mt-0  img_design">
                <img  loading="lazy"  width="" height=""   src={data?.pims_journey_image} alt="pims_journey_image" />
              </div>
            </div>
          </Container>
        </section>
       <div className="bg-light">
       <TestimonialV2 />
       </div>
        <SubmitRFP />
      </div>
    </>
  );
};
export default PrivacyInformationManagementSystem;
