import React from "react";
import { Route, Routes } from "react-router-dom";
import { OurLeadership } from "../../Pages/OurLeadership";
import { Privacy } from "../../Pages/Privacy";
import { Store } from "../../Pages/Store";
import { Error } from "../../Pages/Error";
import { ClientStory } from "../../Pages/ClientStory";
import { MainContainer } from "../MainContainer";
import { ClientStoryDetail } from "../../Pages/ClientStoryDetail";
import { Cookie } from "../../Pages/Cookie";
import { ITGeneralControlsAudit } from "../../Pages/ServicePages/Audit/ITGeneralControlsAudit";
import { NhsInformationGovernanceConsultancy } from "../../Pages/ServicePages/NhsInformationGovernanceConsultancy";
import { BusinessContinuityandDisasterRecoveryAudit } from "../../Pages/ServicePages/Audit/BusinessContinuityandDisasterRecoveryAudit";
import { DataPrivacyCompliance } from "../../Pages/ServicePages/Audit/DataPrivacyCompliance";
import { ExperienceExcellenceinISMSAudit } from "../../Pages/ServicePages/Audit/ExperienceExcellenceinISMSAudit";
import { NetworkSecurityAudit } from "../../Pages/ServicePages/Audit/NetworkSecurityAudit";
import { PhysicalSecurityAudit } from "../../Pages/ServicePages/Audit/PhysicalSecurityAudit";
import { ApplicationSecurityAuditV1 } from "../../Pages/ServicePages/Audit/ApplicationSecurityAuditV1";
import MainService from "../../Pages/MainService";
import Blog from "../../Pages/Blog";
import Industries from "../../Pages/Industries";
import Career from "../../Pages/Career";
import IndividualService from "../../Pages/IndividualService";
import Courses from "../../Pages/Courses";
import Vciso from "../../Pages/ServicePages/Vciso";
import GdprAudit from "../../Pages/ServicePages/GdprAudit";
import DpoService from "../../Pages/ServicePages/DpoService";
import IsoCertiService from "../../Pages/ServicePages/IsoCertiService";
import RecordService from "../../Pages/ServicePages/RecordService";
import CyberSecAudit from "../../Pages/ServicePages/CyberSecAudit";
import CsoService from "../../Pages/ServicePages/CsoService";
import IsoAuditService from "../../Pages/ServicePages/IsoAuditService";
import BusinessConService from "../../Pages/ServicePages/BusinessConService";
import DataProConService from "../../Pages/ServicePages/DataProConService";
import PenetrationTestService from "../../Pages/ServicePages/PenetrationTestService";
import DtacService from "../../Pages/ServicePages/DtacService";
import CyberSecConService from "../../Pages/ServicePages/CyberSecConService";
import CyberEssenCertiService from "../../Pages/ServicePages/CyberEssenCertiService";
import InfoGovConService from "../../Pages/ServicePages/InfoGovConService";
import CyberSecCourse from "../../Pages/CoursesPages/CyberSecCourse";
import CaldiGuardianCourse from "../../Pages/CoursesPages/CaldiGuardianCourse";
import InfoGovTrainingCourse from "../../Pages/CoursesPages/InfoGoveTraining";
import GdprTrainingCourse from "../../Pages/CoursesPages/GdprTraining";
import GdprNhsTrainingCourse from "../../Pages/CoursesPages/GdprNhsTraining";
import DpoTrainingCourse from "../../Pages/CoursesPages/DpoTraining";
import OurSdg from "../../Pages/OurSdg";
import OurValue from "../../Pages/OurValue";
import GdprNhsConsultancy from "../../Pages/ServicePages/GdprNhsConsultancy";
import ClinicalGovernance from "../../Pages/GovernancePages/ClinicalGovernance";
import DataGovernance from "../../Pages/GovernancePages/DataGovernance";
import MergersAcquistionsRisk from "../../Pages/Risk&Resilience/MergersAcquistionsRisk";
import SupplyChain from "../../Pages/Risk&Resilience/SupplyChain";
import EURepresentative from "../../Pages/ManagedService/EURepresentative";
import UKRepresentative from "../../Pages/ManagedService/UKRepresentative";
import DAOTrainingOnline from "../../Pages/CoursesPages/DAOTrainingOnline";
import DPOHealthCare from "../../Pages/CoursesPages/DPOHealthCare";
import AcademicLifeScience from "../../Pages/IndustryPages/AcademicLifeScience";
import CentralandLocalGoverment from "../../Pages/IndustryPages/CentralandLocalGoverment";
import CustomerLoyalityRewards from "../../Pages/IndustryPages/CustomerLoyalityRewards";
import Finance from "../../Pages/IndustryPages/Finance";
import HealthAndSocialCare from "../../Pages/IndustryPages/HealthAndSocialCare";
import Legal from "../../Pages/IndustryPages/Legal";
import PharmaceuticalandPharmacovigilance from "../../Pages/IndustryPages/PharmaceuticalandPharmacovigilance";
import PropertyAndInvestManagment from "../../Pages/IndustryPages/PropertyAndInvestManagment";
import RetailAndFMCG from "../../Pages/IndustryPages/RetailAndFMCG";
import Security from "../../Pages/IndustryPages/Security";
import Technology from "../../Pages/IndustryPages/Technology";
import HIPPA from "../../Pages/ServicePages/Compliance/HIPPA";
import NIST from "../../Pages/ServicePages/Compliance/NIST";
import DataPrivacyConsultancy from "../../Pages/ServicePages/Consultancy/DataPrivacyConsultancy";
import EnterpriseRiskManagementConsultancy from "../../Pages/ServicePages/Consultancy/EnterpriseRiskManagementConsultancy";
import ISO27001Consultancy from "../../Pages/ServicePages/Consultancy/ISO27001Consultancy";
import DisasterRecoveryConsultancy from "../../Pages/ServicePages/Risk&Resilience/DisasterRecoveryConsultancy";
import CyberRiskAndResilience from "../../Pages/ServicePages/Risk&Resilience/CyberRiskAndResilience";
import DPOTrainingforPharma from "../../Pages/CoursesPages/DPOTrainingforPharma";
import DPforPharma from "../../Pages/CoursesPages/DPforPharma";
import Investor from "../../Pages/clientStoryPages/Investor";
import InsuranceAndLossAdjustment from "../../Pages/IndustryPages/InsuranceAndLossAdjustment";
import BiometricDataProtection from "../../Pages/ServicePages/Compliance/BiometricDataProtection";
import AIPrivacyConsultation from "../../Pages/ServicePages/Compliance/AIPrivacyConsultation";
import DataPrivacyImpactService from "../../Pages/ServicePages/Risk&Resilience/DataPrivacyImpactService";
import GDPRDataMappingAndInventory from "../../Pages/ServicePages/Consultancy/GDPRDataMappingAndInventory";
import IncidentResponseAndManagementServices from "../../Pages/ServicePages/Risk&Resilience/IncidentResponseAndManagementServices";
import PrivacyByDesignConsultation from "../../Pages/ServicePages/Consultancy/PrivacyByDesignConsultation";
import PrivacyInformationManagementSystem from "../../Pages/ServicePages/Consultancy/PrivacyInformationManagementSystem";
import CCPA from "../../Pages/ServicePages/Consultancy/CCPA";
import LegalandCompliance from "../../Pages/LegalandCompliance";
import AerospaceAndBorderNavigating from "../../Pages/IndustryPages/AerospaceAndBorderNavigating";
import { ConflictofInterestPolicy } from "../../Pages/LegalCompliance/ConflictofInterestPolicy";
import { AntiModernSlaveryPolicy } from "../../Pages/LegalCompliance/AntiModernSlaveryPolicy";
import { AntiBribery } from "../../Pages/LegalCompliance/AntiBribery";
import { AcceptableUsePolicy } from "../../Pages/LegalCompliance/AcceptableUsePolicy";
import ClimateChangeAndEnviorment from "../../Pages/IndustryPages/ClimateChangeAndEnviorment";
import UnlockingSeamlessMergersAcquisitions from "../../Pages/IndustryPages/UnlockingSeamlessMergersAcquisitions";
import MediaEnquiry from "../../Pages/MediaEnquiry";
import SpeakerEnquiry from "../../Pages/SpeakerEnquiry";
import GdprComplianceToolkit from "../../Pages/StorePages/GdprComplianceToolkit";
import ISO27001Store from "../../Pages/StorePages/ISO27001Store";
import { ViewProduct } from "../../Pages/ViewProduct";
import { Checkout } from "../../Pages/Checkout";
import { TeamDetail } from "../../Pages/team/TeamDeatil";
import { TeamDeatilClaire } from "../../Pages/team/TeamDeatilClaire";
import { TeamDetailBright } from "../../Pages/team/TeamDetailBright";
import { TeamDeatilShaista } from "../../Pages/team/TeamDeatilShaista";
import { TeamDeatilSimonBliss } from "../../Pages/team/TeamDeatilSimonBliss";
import { TeamDeatilAshantel } from "../../Pages/team/TeamDeatilAshantel";
import { TeamDetailHimanshu } from "../../Pages/team/TeamDeatilHimanshu";
import { TeamDeatilLoice } from "../../Pages/team/TeamDeatilLoice";
import { TeamDeatilMarynelle } from "../../Pages/team/TeamDeatilMarynelle";
import ExecutiveResourcing from "../../Pages/ServicePages/Resourcing/ExecutiveResourcing";
import CernerExcellence from "../../Pages/ServicePages/Consultancy/CernerExcellence";
import GDPRCompliance from "../../Pages/ServicePages/Compliance/GDPRCompliance";
import { DataProtectionAudit } from "../../Pages/ServicePages/Audit/DataProtectionAudit";
import ClincalSafetyOfficerHandbook from "../../Pages/StorePages/ClincalSafetyOfficerHandbook";
import NHSDataSecurity from "../../Pages/StorePages/NHSDataSecurity";
import ISO27001ISMS from "../../Pages/StorePages/ISO27001ISMS";
import { Contact } from "../../Pages/Contact";
import StoreCookie from "../../Pages/StorePages/StoreCookie";
import CharityAndNotforprofit from "../../Pages/IndustryPages/CharityAndNotforprofit";
import DCB0129 from "../../Pages/ServicePages/Compliance/DCB0129";
import DCB0160 from "../../Pages/ServicePages/Compliance/DCB0160";
import { BlogDetail } from "../../Pages/BlogDetail";
import CyberScurityStore from "../../Pages/StorePages/CyberScurityStore";
import DataPrivacyStore from "../../Pages/StorePages/DataPrivacyStore";
import { Home } from "../../Pages/Home";
import { TermAndCondition } from "../../pages/TermAndCondition";

export const Router = () => {
  return (
      <Routes>
        <Route path="/" element={<MainContainer />}>
          <Route index element={<Home />} />
          <Route path="/ourteam" element={<OurLeadership />} />
          <Route path="/oursdg" element={<OurSdg />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/store" element={<Store />} />
          <Route path="/product-detail/:id" element={<ViewProduct />} />
          <Route path="/clientstory" element={<ClientStory />} />
          <Route path="/checkout/:id" element={<Checkout />} />
          <Route path="/blogdetail/:id" element={<BlogDetail />} />
          <Route
            path="/clientstorydetail/:url/:id"
            element={<ClientStoryDetail />}
          />
          <Route path="/service" element={<MainService />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/ourvalue" element={<OurValue />} />
          <Route path="/career" element={<Career />} />
          <Route path="/individualservice" element={<IndividualService />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/vciso-services" element={<Vciso />} />
          <Route path="/gdpr-auditors-services" element={<GdprAudit />} />
          <Route
            path="/nhs-information-governance-consultancy"
            element={<NhsInformationGovernanceConsultancy />}
          />
          <Route path="/dpo-services" element={<DpoService />} />
          <Route
            path="/data-protection-compliance"
            element={<DataPrivacyConsultancy />}
          />
          <Route
            path="/iso-27001-certification-services"
            element={<IsoCertiService />}
          />
          <Route
            path="/records-management-auditing-as-a-service"
            element={<RecordService />}
          />
          <Route
            path="/cyber-security-audit-services"
            element={<CyberSecAudit />}
          />
          <Route
            path="/gdpr-nhs-consultancy"
            element={<GdprNhsConsultancy />}
          />
          <Route
            path="/clinical-safety-officer-services"
            element={<CsoService />}
          />
          <Route
            path="/iso-27001-audit-service"
            element={<IsoAuditService />}
          />
          <Route
            path="/iso-22301-business-continuity-gap-analysis-services"
            element={<BusinessConService />}
          />
          <Route
            path="/data-protection-consultancy-2"
            element={<DataProConService />}
          />
          <Route
            path="/iso-27001-penetration-testing"
            element={<PenetrationTestService />}
          />
          <Route path="/dtac-services" element={<DtacService />} />
          <Route
            path="/cyber-security-consultancy-services"
            element={<CyberSecConService />}
          />
          <Route
            path="/cyber-essentials-certification-services"
            element={<CyberEssenCertiService />}
          />
          <Route
            path="/information-governance-consultancy-services"
            element={<InfoGovConService />}
          />
          <Route path="/cyber-security-training" element={<CyberSecCourse />} />
          <Route
            path="/caldicott-guardian-training"
            element={<CaldiGuardianCourse />}
          />
          <Route
            path="/information-governance-training"
            element={<InfoGovTrainingCourse />}
          />
          <Route
            path="/gdpr-nhs-training"
            element={<GdprNhsTrainingCourse />}
          />
          <Route path="/ig-gdpr-training" element={<GdprTrainingCourse />} />
          <Route
            path="/data-protection-officer-training-online"
            element={<DpoTrainingCourse />}
          />
          <Route path="/clinicalgovernance" element={<ClinicalGovernance />} />
          <Route path="/datagovernance" element={<DataGovernance />} />
          <Route
            path="/mergeracquistion"
            element={<MergersAcquistionsRisk />}
          />
          <Route path="/supplychain" element={<SupplyChain />} />
          <Route path="/eurepresentative" element={<EURepresentative />} />
          <Route path="/ukrepresentative" element={<UKRepresentative />} />
          <Route
            path="/data-assest-owner-training-online"
            element={<DAOTrainingOnline />}
          />
          <Route
            path="/data-protection-officer-training-for-health-and-socialcare"
            element={<DPOHealthCare />}
          />

          {/* Legal and Compliance */}
          <Route
            path="/legal-and-compliance"
            element={<LegalandCompliance />}
          />
          <Route
            path="/conflict-of-interest-policy"
            element={<ConflictofInterestPolicy />}
          />
          <Route
            path="/antimodern-slavery-policy"
            element={<AntiModernSlaveryPolicy />}
          />
          <Route
            path="/anti-bribery"
            element={<AntiBribery />}
          />
          <Route
            path="/acceptable-use-policy"
            element={<AcceptableUsePolicy />}
          />

          {/* Industry Pages */}
          <Route
            path="/charity-and-not-for-profit"
            element={<CharityAndNotforprofit />}
          />
          <Route
            path="/academic-and-life-science-search"
            element={<AcademicLifeScience />}
          />
          <Route
            path="/central-and-local-government"
            element={<CentralandLocalGoverment />}
          />
          <Route
            path="/cutomer-loyality-and-rewards"
            element={<CustomerLoyalityRewards />}
          />
          <Route path="/finance" element={<Finance />} />
          <Route
            path="/healthandsocialcare"
            element={<HealthAndSocialCare />}
          />
          <Route
            path="/loss-adjustment"
            element={<InsuranceAndLossAdjustment />}
          />
          <Route path="/legal" element={<Legal />} />
          <Route
            path="/pharmaceutical-and-pharmacovigilance"
            element={<PharmaceuticalandPharmacovigilance />}
          />
          <Route
            path="/property-and-investment-managment"
            element={<PropertyAndInvestManagment />}
          />
          <Route path="/retail" element={<RetailAndFMCG />} />
          <Route path="/security" element={<Security />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/investor" element={<Investor />} />
          <Route path="/privacypolicy" element={<Privacy />} />
          <Route
            path="/aerospace-and-border-control-navigating-secure-skies-and-borders"
            element={<AerospaceAndBorderNavigating />}
          />
          <Route path="/cookie" element={<Cookie />} />
          <Route path="/merge-and-acquisitions" element={<UnlockingSeamlessMergersAcquisitions />} />
          <Route path="/climate-change-and-enviornment" element={<ClimateChangeAndEnviorment />} />

          {/* Service Pages */}
          <Route path="/hippa" element={<HIPPA />} />
          <Route path="/nist" element={<NIST />} />
          <Route
            path="/dataprivacyconsultancy"
            element={<DataPrivacyConsultancy />}
          />
          <Route
            path="/enterpriseriskmanagment"
            element={<EnterpriseRiskManagementConsultancy />}
          />
          <Route
            path="/iso-27001-consultancy"
            element={<ISO27001Consultancy />}
          />
          <Route
            path="/disaster-recovery-consultancy"
            element={<DisasterRecoveryConsultancy />}
          />
          <Route
            path="/cyber-risk-and-resilience"
            element={<CyberRiskAndResilience />}
          />
          <Route
            path="/data-protection-officer-training-for-pharma"
            element={<DPOTrainingforPharma />}
          />
          <Route
            path="/data-privacy-training-for-pharma"
            element={<DPforPharma />}
          />
          <Route
            path="/bio-data-protection-consultancy"
            element={<BiometricDataProtection />}
          />
          <Route
            path="/ai-and-privacy-consultancy"
            element={<AIPrivacyConsultation />}
          />
          <Route
            path="/application-security-audit"
            element={<ApplicationSecurityAuditV1 />}
          />
          <Route
            path="/buisness-countinity-and-disaster-recovery-audit"
            element={<BusinessContinuityandDisasterRecoveryAudit />}
          />
          <Route
            path="/data-privacy-compliance"
            element={<DataPrivacyCompliance />}
          />
          <Route
            path="/data-privacy-impact-service"
            element={<DataPrivacyImpactService />}
          />
          <Route
            path="/experience-excellence-in-isms-audit"
            element={<ExperienceExcellenceinISMSAudit />}
          />
          <Route
            path="/gdpr-data-mapping-and-inventory"
            element={<GDPRDataMappingAndInventory />}
          />
          <Route
            path="/incident-response-and-managment-service"
            element={<IncidentResponseAndManagementServices />}
          />
          <Route
            path="/it-general-control-audit"
            element={<ITGeneralControlsAudit />}
          />
          <Route
            path="/network-security-audit"
            element={<NetworkSecurityAudit />}
          />
          <Route
            path="/physical-security-audit"
            element={<PhysicalSecurityAudit />}
          />
          <Route
            path="/privacy-by-design-consultation"
            element={<PrivacyByDesignConsultation />}
          />
          <Route
            path="/privacy-information-management-system"
            element={<PrivacyInformationManagementSystem />}
          />
          <Route path="/ccpa-and-cpra-compliance" element={<CCPA />} />
          <Route path="/term-and-condition" element={<TermAndCondition />} />

          <Route path="*" element={<Error />} />
          
          {/* Store */}
          <Route path="/gdpr-compliance-toolkit" element={<GdprComplianceToolkit />} />
          <Route path="/iso-27001-toolkit" element={<ISO27001Store />} />

          {/* Footer */}
          <Route path="/media-enquiry" element={<MediaEnquiry />} />
          <Route path="/speaker-enquiry" element={<SpeakerEnquiry />} />

          {/* Team Detail Pages */}
          <Route path="/team-detail/michael" element={<TeamDetail />} />
          <Route path="/team-detail/claire" element={<TeamDeatilClaire />} />
          <Route path="/team-detail/bright" element={<TeamDetailBright />} />
          <Route path="/team-detail/shaista" element={<TeamDeatilShaista />} />
          <Route path="/team-detail/simonbliss" element={<TeamDeatilSimonBliss />} />
          <Route path="/team-detail/ashantel" element={<TeamDeatilAshantel />} />
          <Route path="/team-detail/himanshu" element={<TeamDetailHimanshu />} />
          <Route path="/team-detail/loice" element={<TeamDeatilLoice />} />
          <Route path="/team-detail/marynelle" element={<TeamDeatilMarynelle />} />
          
          <Route path="/executive-resourcing" element={<ExecutiveResourcing />} />
          <Route path="/cerner-excellence" element={<CernerExcellence />} />
          <Route path="/gdpr-compliance" element={<GDPRCompliance />} />
          <Route path="/data-protection-audit" element={<DataProtectionAudit />} />
          <Route path="/clinical-safety-officer-handbook" element={<ClincalSafetyOfficerHandbook />} />
          <Route path="/cyber-security-store" element={<CyberScurityStore />} />
          <Route path="/data-privacy-store" element={<DataPrivacyStore />} />
          <Route path="/nhs-data-security" element={<NHSDataSecurity />} />
          <Route path="/iso-27001-isms" element={<ISO27001ISMS />} />
          <Route path="/cookie-template" element={<StoreCookie />} />
          <Route path="/dcb0129" element={<DCB0129 />} />
          <Route path="/dcb0160" element={<DCB0160 />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
      </Routes>
  );
};
