import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import banImg from "../../Assets/Images/teamDetail.jpg";
import team from "../../Assets/Images/team/team6.jpg";
import education from "../../Assets/Images/team/education.jpg";
import experience from "../../Assets/Images/team/experience.jpg";
import teamPrice from "../../Assets/Images/teamPrice.jpg";
import teamStar from "../../Assets/Images/teamStar.jpg";
import teamManager from "../../Assets/Images/teamManager.jpg";
import teamMic from "../../Assets/Images/teamMic.jpg";
import teamEducation from "../../Assets/Images/team-education.jpg";
import { TestimonialV2 } from "../../Components/TestimonialV2";
import { Loader } from "../../Components/Loader";

export const TeamDeatilAshantel = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <section
        className={"inner-bnr banner-section"}
        style={{
          backgroundImage: `url(${banImg})`,
          backgroundPosition: "right",
          padding: "100px ",
        }}
      >
        <Container>
          <div className="banner_header">
            <h2 className="fw-bold mb-3 text-uppercase text-left">Team</h2>
          </div>
        </Container>
      </section>

      <section className="sec-padding">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={team} className="img-fluid" alt="team img" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                {/* <h3>About us</h3> */}
                <h3>Ashantel Lachhani </h3>
                <div className="ct_card_name mt-2">
                LLB (Hons) CIPP/E CIPM FIP
                </div>
                <div className="team-designation">
                Data Protection Luminary | Legal & Compliance Expert | Privacy Advocate
                </div>
                <p>
                With over a decade of relentless dedication to the legal and compliance arena, I bring forth a profound understanding of data protection laws, including the General Data Protection Regulation (GDPR) and the Privacy and Electronic Communications Directive. My journey has been an odyssey through the realms of healthcare, property, direct marketing, and cutting-edge technologies such as AI and IoT.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                {/* <h3>About us</h3> */}
                <h3 className="mb-2">About Me </h3>
                <ul>
                  <li>
                  	<b>Guardian of Data Integrity:</b> Throughout my career, I have championed the creation and integration of privacy and security frameworks for ground-breaking technologies like AI-driven behavioural advertising algorithms and IoT, safeguarding the sanctity of data in a dynamic digital age.
                  </li>
                  <li>
                  <b>First Line of defence:</b> As a trusted advisor, I have held full accountability to board executives, ensuring they are well-informed about the ever-evolving risks to data. I've acted as the first point of contact for managing incidents and data breaches, consistently liaising with regulators and customers.
                  </li>
                  <li>
                  <b>Cultural Catalyst:</b> My passion extends beyond compliance; I've embarked on a mission to embed a culture of data respect and quality intelligence across organisations. Education and nurturing strong stakeholder relationships have been my driving forces.
                  </li>
                </ul>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={education} className="img-fluid cover" alt="education" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={teamPrice} className="img-fluid cover" alt="teamPrice" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Accolades </h3>
                <ul>
                <li>2021/2023 IAPP Advisory Board Member (Women Leading Privacy)</li>
                <li>PICCASO Privacy Awards, Privacy Leader of the Year Finalist 2022</li>
                <li>Women in GRC Awards Finalist 2021</li>
                <li>Awarded IAPP Fellow of Information Privacy 2020</li>
                <li>CIPM 2020</li>
                <li>CIPP/E 2019</li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </section>

      
      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Professional Journey </h3>
                <ul>
                  <li>Director of Information Governance and Data Protection - The Hyde Group UK | Aug 2020 - Mar 2023</li>
                  <li>Data Protection Strategy Lead - Diabetes UK, London | Jun 2017 - Jun 2020</li>
                  <li>Head of Information Governance/Security - National Health Service, London | Jan 2015 - Jun 2017</li>
                </ul>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience} className="img-fluid cover" alt="experience" />
            </div>
          </div>
        </div>
      </section>


      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
          <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={teamStar} className="img-fluid cover" alt="teamStar" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Key Accomplishments</h3>
                <ul>
                <li>Developed and embedded the strategic direction for information management and data compliance, including navigating the complexities of the UK GDPR and various data protection regulations.</li>
                <li>Pioneered a new Information Governance function, recruiting a high-performing team, even during the pandemic.</li>
                <li>Championed successful technology programs worth over twenty million pounds, ensuring data protection compliance and risk assessment.</li>
                <li>Influenced and negotiated a collaborative approach with stakeholders, fostering a culture of information governance.</li>

                </ul>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      
      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
          
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Board Positions Held</h3>
                <ul>
                <li>Non-Executive Director at Lewisham and Greenwich NHS Trust - Director Scheme</li>
                <li>Co-Chair of the London Information Governance Managers’ NHS Forum</li>
                <li>Non-Executive Director at Barts Health NHS Trust - NExT Director Scheme</li>
                <li>Council Member at the Royal Society of Medicine of the Open Section</li>
                <li>Founder/Managing Director - buy4meLondon.com</li>
                </ul>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={teamManager} className="img-fluid cover" alt="teamManager" />
            </div>
          </div>
        </div>
      </section>


      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
          <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={teamMic} className="img-fluid cover" alt="teamMic" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Contributions at Events</h3>
                <ul>
                    <li>Keynote Speaker at Social Housing Strategy Forum 2022</li>
                    <li>Privacy Speaker at IAPP Diversity in Privacy Section Mentor Q&A 2022</li>
                    <li>Privacy Speaker at Data Protection World Forum 2020</li>
                    <li>American Bar Association International Law Section 2020</li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>
      </section>


      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
          
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Education History</h3>
                <ul>
                    <li>BPP Law School London - Legal Practice Course</li>
                    <li> University of Essex - Law LLB (Hons) - Achieved 1st Class Dissertation</li>
                </ul>
                <h3 className="mb-2">Connect with Me  </h3>
                <a target="_blank" href="https://chat.openai.com/">Email Me |</a>
                <p>Join me in the journey to fortify data protection, foster privacy, and lead organisations towards a secure digital future.</p>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={teamEducation} className="img-fluid cover" alt="teamEducation" />
            </div>
          </div>
        </div>
      </section>

      <div className="bg-light">
        <TestimonialV2 />
      </div>
    </>
  );
};
