import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import banImg from "../../Assets/Images/teamDetail.jpg";
import team from "../../Assets/Images/team/loice.jpeg";
import education from "../../Assets/Images/team/education.jpg";
import experience from "../../Assets/Images/team/experience.jpg";
import { TestimonialV2 } from "../../Components/TestimonialV2";
import { Loader } from "../../Components/Loader";

export const TeamDeatilLoice = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <section
        className={"inner-bnr banner-section"}
        style={{
          backgroundImage: `url(${banImg})`,
          backgroundPosition: "right",
          padding: "100px ",
        }}
      >
        <Container>
          <div className="banner_header">
            <h2 className="fw-bold mb-3 text-uppercase text-left">Team</h2>
          </div>
        </Container>
      </section>

      <section className="sec-padding">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={team} className="img-fluid" alt="teamimg" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                {/* <h3>About us</h3> */}
                <h3>Loice Kerubo Nyaribo</h3>
                <div className="ct_card_name mt-2">
                Kenyan-Qualified Advocate | Technology, Media, and Telecommunications Law
                </div>                
                <p>
                Loice Kerubo Nyaribo is a Kenyan-qualified advocate with a Master's Degree in Technology, Media, and Telecommunications Law from Queen Mary University of London. Loice combines her background in commercial law, privacy and data protection law, and technology law with exceptional interpersonal skills to excel in problem-solving and client-oriented roles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                {/* <h3>About us</h3> */}
                <h3 className="mb-2">Qualifications </h3>
                <ul>
                <li>LLM, Technology, Media and Telecommunications Law Queen Mary University of London (2021-2022)
                Key Courses: Data Protection Law, Commercialization of IP, Legal Technology, E-Commerce Transactions, Illegal Speech, Censorship and Digital Rights: Social Media vs “Old” Media, Transnational Law, Media Regulation: Reputation Management and the Business of Film.</li>
                <li>Higher Diploma in Human Resource Management College of Human Resource Management (2019-2021)
                Certification: Employment law and employee management certification in Kenya. (Credit 3 - highest grade is a Credit 4).</li>
                <li>Post-graduate Diploma in Law/ Kenyan Bar Kenya School of Law, Nairobi (2017-2018)
                Admission: Admitted to the Kenyan bar in February 2019.</li>
                <li>LLB, Bachelor of Laws Strathmore University, Nairobi (2012-2016)
                Degree Classification: Second Class Honours, Upper Division (2:1).</li>

                </ul>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={education} className="img-fluid cover" alt="educationimg" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience} className="img-fluid cover" alt="experienceImg" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Certifications </h3>                
                <ul>
                <li>Advocate of the High Court of Kenya</li>
                <li>French A1 Certification: Alliance Française Nairobi</li>
                </ul>
                <h3 className="my-3">Languages</h3>
                <ul>
                <li>English: Fluent</li>
                <li>Swahili: Fluent</li>
                <li>French: Beginner</li>
                </ul>
                <h3 className="my-3">Hobbies and Interests</h3>
                <ul>
                <li>Swimming, watching films, acting, traveling, singing, and reading.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-light">
        <TestimonialV2 />
      </div>
    </>
  );
};
