export const contactTabs ={
    submit : 'submit',
    propsal : 'propsal' ,
    callback : 'callback'
} 

export const CoursePagesID = [
    "31310",
    "31312",
    "31314",
    "31316",
    "31318",
    "31319",
    "31321",
    "31323",
    "31221",
    "32325"
]


export const StorePagesId = [
    "31351",
    "31218",
    "31215",
    "30090",
    "31053",
    "30112",
    "30758"
]