import React from "react";
import documentation from "../Assets/Images/documentation.png";
import { Link } from "react-router-dom";

export const PDFButton = ({link}) => {
  return (
    <div className={`dwl-pdf`}>
      <Link to={link} target="_blank">
        <img  loading="lazy"  width="" height=""   src={documentation} alt="documentation" className="img-fluid" />
      </Link>
    </div>
  );
};
