import React, { useEffect } from "react";
import "./App.css";
import "./assets/css/home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Router } from "./Layout/routes/Router";
import { ToastContainer } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-modern-drawer/dist/index.css";
import { initGA, logPageView } from "./Components/GoogleAnalytics";
const queryClient = new QueryClient();

function App() {
  const stripePromise = loadStripe(`${import.meta.env.REACT_APP_STRIPE_PUBLISH_KEY}`);
 
  let pathName;
  useEffect(() => {
    if(typeof window !== "undefined"){
      pathName = window?.location.pathname;
    }
  }, []);
  
  // useEffect(() => {
  //     initGA();
  //     logPageView();
  // }, [pathName]);
  
  return (
    <QueryClientProvider client={queryClient}>
      <Elements stripe={stripePromise}>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <ToastContainer />

        <Router />
      </Elements>
    </QueryClientProvider>
  );
}

export default App;
