import React from "react";
import { Container } from "react-bootstrap";
import Banner from "../../../Components/Banner";
import SubmitRFP from "../../../Components/SubmitRFP";
import { GDPR_COMPLIANCE } from "../../../services/Url";
import { useCustomGet } from "../../../services/Service";
import { TestimonialV2 } from "../../../Components/TestimonialV2";
import { Loader } from "../../../Components/Loader";
import ServiceSlider from "../../../Components/ServiceSlider";

const GDPRCompliance = () => {
  const { data, isLoading } = useCustomGet(GDPR_COMPLIANCE);

  var bannerContent = {
    title: `<h1>${data?.banner_main_heading}</h1>`,
    cardTitle:data?.banner_heading,
    cardPara:data?.banner_sub,
    bannerbtn:'Get Started with GDPR Compliance'
  }

  return (
    <>
      {
        isLoading ? 
        <Loader /> :''
      }
      <Banner bannerCnt={bannerContent} banImg={data?.banner_image} />
      <section className=" bg-light service-trusted-by">
          <Container>
           <ServiceSlider />
           </Container>
        </section>
      <div className="internal-service-page" id="record-mang-service">
      
      <section
          className="award-sec sec-padding-bottom"
          style={{ backgroundColor: "#fff" }}
        >
          <Container>
            {data?.gdpr_content_box?.map((item, i) => {
              if (i % 2 === 0) {
                return (
                  <div key={i} className="mt-5">
                    {item?.gdpr_content_box_heading && (
                      <h2 className="sec-heading">{item?.gdpr_content_box_heading}</h2>
                    )}
                    <div className="complex-chng-des chng-des-md">
                      <div className="cmpx-img-card mt-0 img_design">
                        <img  loading="lazy"  width="" height=""   src={item?.gdpr_content_box_image} alt="gdpr_content_box_image" />
                      </div>
                      <div className="cmpx-chng-des ">
                        <div className="ltd-case-description mt-md-4 mt-3">
                          <div
                            className="custom-list"
                            dangerouslySetInnerHTML={{
                              __html: item?.gdpr_content_box_content,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={i}  className="mt-5">
                    {item?.gdpr_content_box_heading && (
                      <h2 className="sec-heading">{item?.gdpr_content_box_heading}</h2>
                    )}
                    <div className="complex-chng-des chng-des-md">
                      <div className="cmpx-chng-des ">
                        <div className="ltd-case-description mt-md-4 mt-3">
                          <div
                            className="custom-list"
                            dangerouslySetInnerHTML={{
                              __html: item?.gdpr_content_box_content,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="cmpx-img-card mt-0 img_design">
                        <img  loading="lazy"  width="" height=""   src={item?.gdpr_content_box_image} alt="gdpr_content_box_image" />
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </Container>
        </section>
       <div className="bg-light">
       <TestimonialV2 />
       </div>
        <SubmitRFP />
      </div>
    </>
  );
};
export default GDPRCompliance;
