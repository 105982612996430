import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import banImg from "../../Assets/Images/teamDetail.jpg";
import team from "../../Assets/Images/team/team7.jpg";
import education from "../../Assets/Images/team/education.jpg";
import experience from "../../Assets/Images/team/experience.jpg";
import experience2 from "../../Assets/Images/team/exp2.jpg";
import teamBright from "../../Assets/Images/Team-bright.jpg";
import teamBright2 from "../../Assets/Images/team-bright2.jpg";
import award from "../../Assets/Images/team/award.jpg";
import { TestimonialV2 } from "../../Components/TestimonialV2";
import { Loader } from "../../Components/Loader";

export const TeamDetailBright = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <section
        className={"inner-bnr banner-section"}
        style={{
          backgroundImage: `url(${banImg})`,
          backgroundPosition: "right",
          padding: "100px ",
        }}
      >
        <Container>
          <div className="banner_header">
            <h2 className="fw-bold mb-3 text-uppercase text-left">Team</h2>
          </div>
        </Container>
      </section>

      <section className="sec-padding">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={team} className="img-fluid" alt="teamimg" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                {/* <h3>About us</h3> */}
                <h3>Dr. Bright G. Mawudor</h3>
                <div className="ct_card_name mt-2">PhD</div>
                <div className="team-designation">
                  Senior Cyber Security Consultant
                </div>
                <p>
                  Just like art, technology is a soaring exercise of human
                  ingenuity. I have always enjoyed bringing to insight
                  imagination through driving technology advancements that form
                  formidable solutions to problems in society.
                </p>
                <p>
                  My career goes beyond making money. I believe it's a true
                  expression of my passion, life purpose, and creativity. The
                  result is that I am profoundly engaged, inquisitive,
                  productive, and fulfilled.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                {/* <h3>About us</h3> */}
                <h3 className="mb-2">Professional Expertise</h3>
                <p>
                  With my growing expertise in information technology, I am a
                  seasoned Cybersecurity Engineer with strong expertise in Cyber
                  resilience strategy building, risk management adoption,
                  vulnerability management, and system penetration testing. I
                  have presented at over 140 Cyber Security conferences,
                  lectured at various universities, and contributed to
                  cybersecurity publications.
                </p>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={education} className="img-fluid cover" alt="education1img" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience} className="img-fluid cover" alt="experience" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Key Competencies </h3>
                <ul>
                  <li>Cyber Security Engineer for 10 years</li>
                  <li>Information Security</li>
                  <li>Web Application Security</li>
                  <li>Network Vulnerability Assessment</li>
                  <li>Corporate Governance</li>
                  <li>Risk Assessment & Mitigation</li>
                  <li>Cyber Security Training</li>
                  <li>FOSS Enthusiast</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <p>
                  I capitalise lean analytical and problem-solving skills to
                  maximise network performance through Cyber Security tools,
                  implementing load-balancing techniques, and steering system
                  optimisation. I am adept at streamlining processes,
                  procedures, and conceptualising technological strategies to
                  improve bottom-line performance, optimise system performance,
                  productivity, and increase profitability. I have submitted
                  bugs to organisations such as Safaricom, Zuku, and Multichoice
                  group in the past, among many others pertaining to Cyber
                  Security of systems.
                </p>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience2} className="img-fluid cover" alt="experience2" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={award} className="img-fluid cover" alt="award" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Entrepreneurial Spirit </h3>

                <p>
                  Coupled with my skill set is a strong entrepreneurial
                  instinct, which I engaged to pioneer the Cyber Security
                  collective Africahackon. An organisation that aims at
                  nurturing talent in the Cyber Security space. Africahackon has
                  converted over 60 professionals to practice Cybersecurity as
                  their full-time job with the yearly conference drawing lots of
                  professionals from across the globe and has ever since seen
                  growth over the years.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Technical Proficiency</h3>

                <ul>
                  <li>
                    Good command of Windows Active Directory, Exchange, SQL,
                    TCP/IP, DHCP, and DNS.
                  </li>
                </ul>

                <h3 className="my-2">Career Milestones</h3>

                <ul>
                  <li>
                    A monumental achievement in my career was being recognised
                    as Top 40 Under 40 Entrepreneurs 2016 in Kenya.
                  </li>
                  <li>
                    Highlighted as a Top IT Personnel in 2019 and Rocheston
                    Academy Cyber leader in 2021 in the industry.
                  </li>
                </ul>
              </div>
            </div>

            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={teamBright} className="img-fluid cover" alt="teamBright" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={teamBright2} className="img-fluid cover" alt="teamBright2" />
            </div>

            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Professional Approach</h3>
                <p>
                  I am a tech-savvy professional with the ability to liaise with
                  technology developments to execute plans as well as develop
                  strategies to increase efficiency and enhance ROI.
                </p>
                <p>
                  Dr. Bright G. Mawudor brings a wealth of experience and
                  expertise to the role of Senior Cyber Security Consultant at
                  IG-Smart Ltd. His passion for cybersecurity, innovation, and
                  dedication to shaping the industry make him an invaluable
                  asset to our team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-light">
        <TestimonialV2 />
      </div>
    </>
  );
};
