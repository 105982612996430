import React from "react";
import { Container } from "react-bootstrap";
import InnerBanner from "../../Components/InnerBanner";
import { Loader } from "../../Components/Loader";
import { ANTI_MODERN_SLAVERY } from "../../services/Url";
import { useCustomGet } from "../../services/Service";

export const AntiModernSlaveryPolicy = () => {
  const { data, isLoading } = useCustomGet(ANTI_MODERN_SLAVERY)

  var bannerContent = {
    cardTitle: data?.ams_banner_heading,
    cardPara: "",
  }

  return (
    <>
     {
        isLoading ? 
        <Loader /> :''
      }
      <InnerBanner bannerCnt={bannerContent} banImg={data?.ams_banner_image} />
      <div className="internal-service-page" id="cyber-essen-certi-service">
        <Container>
        <div className="privacy custom-list sec-padding"
            dangerouslySetInnerHTML={{__html: data?.ams_content}}
        >
        </div>
        </Container>
      </div>
    </>
  );
};
