import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import banImg from "../../Assets/Images/teamDetail.jpg";
import team from "../../Assets/Images/team/team4.jpg";
import education from "../../Assets/Images/team/education.jpg";
import experience from "../../Assets/Images/team/experience.jpg";
import experience2 from "../../Assets/Images/team/exp2.jpg";
import teamEducation from "../../Assets/Images/team-education.jpg";
import award from "../../Assets/Images/team/award.jpg";
import email from "../../Assets/Images/email.png";
import phonecall from "../../Assets/Images/phone-call.png";
import { TestimonialV2 } from "../../Components/TestimonialV2";
import { Loader } from "../../Components/Loader";

export const TeamDetailHimanshu  = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <section
        className={"inner-bnr banner-section"}
        style={{
          backgroundImage: `url(${banImg})`,
          backgroundPosition: "right",
          padding: "100px ",
        }}
      >
        <Container>
          <div className="banner_header">
            <h2 className="fw-bold mb-3 text-uppercase text-left">Team</h2>
          </div>
        </Container>
      </section>

      <section className="sec-padding">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={team} className="img-fluid" alt="teamimg" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3>Himanshu Desai</h3>
                <div className="ct_card_name mt-2">
                MSc, MBA
                </div>
                <div className="team-designation">
                Health Informatics Professional | Strategic Leader | Healthcare Transformer
                </div>
                <p>
                With over 25 years of healthcare and health informatics expertise, I bring a wealth of knowledge in leadership, team management, and the design and implementation of health informatics solutions across various hospital settings. My journey, which has spanned continents and multiple prestigious institutions, has equipped me with a unique blend of skills that I now seek to leverage in senior leadership roles, focusing on management, organisational strategy, and hospital operational leadership.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">About Me </h3>
                <ul>
                <li><b>Healthcare Alchemist: </b> I have had the privilege of serving as the Head of Pharmacy Information Systems at Sidra Medicine in Doha, Qatar, where I led a dynamic team to deliver critical pharmacy projects, consistently exceeding organisational priorities.</li>
                <li><b>Champion of Excellence:</b> My leadership extends beyond pharmacy to encompass the entire healthcare landscape, where I've spearheaded and managed diverse projects to optimise medication management, supply chain efficiency, and clinical decision support.</li>
                <li><b>Strategic Visionary:</b> I've had the honour of shaping the future of healthcare through strategic planning initiatives, defining the direction of the Department of Medical Informatics at Sidra Medicine in Qatar.</li>

                </ul>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={education} className="img-fluid cover" alt="educationimg" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience} className="img-fluid cover" alt="experienceimg" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Key Achievements </h3>
                
                <ul>
                  <li>
                  Successfully managed the implementation of the Cerner-Oracle interface to capture supply chain movements of medications throughout the hospital.
                  </li>
                  <li>
                  Led the design, implementation, and go-live of Cerner PharmNet, enhancing medication management efficiency.
                  </li>
                  <li>
                  Program managed Medicines Management Optimisation projects, ensuring the safety and utilisation of medications across the hospital.
                  </li>
                  <li>Guided teams through EMR-related hospital activations for paediatric services.</li>
                  <li>Proven track record of recruiting, building, leading, and managing high-performance teams.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">
                Experience Snapshot
                </h3>
                <ul>
                <li>Head Pharmacy Information Systems | Sidra Medicine, Doha, Qatar | July 2020 - August 2023</li>
                <li>Business Relationship Partner | Sidra Medicine, Doha, Qatar | 2019 - July 2020</li>
                <li>Manager – Clinical Applications – Paediatrics, Department of Medical Informatics | Sidra Medicine, Doha, Qatar | 2016 - 2019</li>
                <li>Clinical Application Specialist – Paediatrics, Department of Medical Informatics | Sidra Medicine, Doha, Qatar | Aug 2013 – Oct 2013</li>
                <li>Business Relationship Manager | Royal Brompton and Harefield NHS Trust | 2013 – 2014</li>
                <li>Pharmacy Informatics Consultant | Noemalife, UK | 2013 - 2014</li>
                <li>Senior Transformation Manager | Guys’ and St Thomas’ NHS Trust, London | Feb 2013 – Aug 2014</li>
                <li>UK Clinical Information Pharmacist | Cerner Ltd, UK | December 2009 – Aug 2012</li>

                </ul>
                
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience2} className="img-fluid cover" alt="experience2" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={teamEducation} className="img-fluid cover" alt="teamEducation" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Education </h3>

               <ul>
               <li>Harvard iLead Leadership Program | Jasper University | October 2018</li>
                <li>MSc Health Informatics | University College London, UK | 2008 - 2011</li>
                <li>MBA | University of Aston Business School, UK | 2002 – 2005</li>
                <li>BSc (HONS) Pharmacy | University of Manchester, UK | 1993 – 1996</li>

               </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
           
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Skills & Professional Memberships </h3>

               <ul>
               <li>Member of The Royal Pharmaceutical Society of Great Britain | UK | Since 1997</li>
               </ul>
               <h3 className="mt-3 mb-3">Connect with Me:</h3>
               <ol className="contact-links mb-2">
              <li>
                <a href="tel:+4402071674268">
                  <span>
                    <img  loading="lazy"  width="" height=""   src={phonecall} alt="phonecall" />
                  </span>{" "}
                  <pre>+44 0 20 7167 4268</pre>
                </a>
              </li>
              <li>
                <a href="mailto:info@ig-smart.com">
                  <span>
                    <img  loading="lazy"  width="" height=""   src={email} alt="email" />
                  </span>
                  info@ig-smart.com
                </a>
              </li>
            </ol>
               <p>Let's explore the transformative possibilities in healthcare together. Whether you're looking for a seasoned healthcare leader or seeking strategic guidance, I'm here to connect, collaborate, and create a healthier future.</p>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={award} className="img-fluid cover" alt="awardimg" />
            </div>
          </div>
        </div>
      </section>

      <div className="bg-light">
        <TestimonialV2 />
      </div>
    </>
  );
};
