import React from "react";
import { Container } from "react-bootstrap";
import Banner from "../../Components/Banner";
import SubmitRFP from "../../Components/SubmitRFP";
import { useCustomGet } from "../../services/Service";
import { TestimonialV2 } from "../../Components/TestimonialV2";
import { Loader } from "../../Components/Loader";
import { ACCEPTABLE_USE_POLICY } from "../../services/Url";

export const AcceptableUsePolicy = () => {
  const { data, isLoading } = useCustomGet(ACCEPTABLE_USE_POLICY);

  var bannerContent = {
    title: `<h1>${data?.aup_banner_heading}</h1>`,
    cardTitle:'',
    cardPara:data?.aup_banner_content,
  }

  return (
    <>
      {
        isLoading ? 
        <Loader /> :''
      }
      <Banner bannerCnt={bannerContent} banImg={data?.aup_banner_image} />
      <div className="internal-service-page" id="record-mang-service">
      <section
          className="award-sec sec-padding"
          style={{ backgroundColor: "#fff" }}
        >
          <Container>
           {
            data?.aup_activity_content_box?.map((item, i) => {
                if(i % 2 === 0){
                  return( <React.Fragment key={i}>
                         {item?.abcn_elevating_heading && <h2 className="sec-heading">
                            {item?.aup_activity_heading}
                            </h2>}
                            <div className="complex-chng-des chng-des-md">
                            <div className="cmpx-img-card mt-0 img_design">
                                <img  loading="lazy"  width="" height=""   src={item?.aup_activity_image} alt="aup_activity_image" />
                            </div>
                            <div className="cmpx-chng-des ">
                                <div className="ltd-case-description mt-md-4 mt-3">
                                <div className="custom-list"
                                    dangerouslySetInnerHTML={{__html: item?.aup_activity_content}}
                                >             
                                </div>
                                </div>
                            </div>
                            </div>
                    </React.Fragment>)
                }else{
                    return(<React.Fragment key={i}>
                    {item?.abcn_elevating_heading && <h2 className="sec-heading">
                            {item?.aup_activity_heading}
                            </h2>}
                       <div className="complex-chng-des chng-des-md">
                       <div className="cmpx-chng-des ">
                           <div className="ltd-case-description mt-md-4 mt-3">
                           <div className="custom-list"
                               dangerouslySetInnerHTML={{__html: item?.aup_activity_content}}
                           >             
                           </div>
                           </div>
                       </div>
                       <div className="cmpx-img-card mt-0 img_design">
                           <img  loading="lazy"  width="" height=""   src={item?.aup_activity_image} alt="aup_activity_image" />
                       </div>
                       
                       </div>
               </React.Fragment>) 
                }
            })
           }
          </Container>
        </section>
        
       <div className="bg-light">
       <TestimonialV2 />
       </div>
        <SubmitRFP />
      </div>
    </>
  );
};
