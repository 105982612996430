import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import banImg from "../../Assets/Images/teamDetail.jpg";
import team from "../../Assets/Images/team/team2.jpg";
import education from "../../Assets/Images/team/education.jpg";
import experience from "../../Assets/Images/team/experience.jpg";
import { TestimonialV2 } from "../../Components/TestimonialV2";
import { Loader } from "../../Components/Loader";

export const TeamDeatilMarynelle = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <section
        className={"inner-bnr banner-section"}
        style={{
          backgroundImage: `url(${banImg})`,
          backgroundPosition: "right",
          padding: "100px ",
        }}
      >
        <Container>
          <div className="banner_header">
            <h2 className="fw-bold mb-3 text-uppercase text-left">Team</h2>
          </div>
        </Container>
      </section>

      <section className="sec-padding">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={team} className="img-fluid" alt="teamimg" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                {/* <h3>About us</h3> */}
                <h3>Marynelle Li</h3>
                <div className="ct_card_name mt-2">
                  Executive Assistant & Marketing Coordinator
                </div>
                <p>
                  Marynelle Li is a dynamic and highly organised professional
                  who excels in her role as an Executive Assistant & Marketing
                  Coordinator. With a background in both administrative support
                  and marketing, Marynelle brings a diverse skill set to her
                  position, making her an invaluable asset to the team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                {/* <h3>About us</h3> */}
                <h3 className="mb-2">
                  Executive Assistant & Marketing Coordinator - IG-Smart Ltd{" "}
                </h3>
                <ul>
                  <li>
                    Marynelle plays a pivotal role in ensuring the smooth
                    operation of daily activities for the executive team.
                  </li>
                  <li>
                    Manages calendars, schedules, and appointments, optimising
                    productivity and efficiency.
                  </li>
                  <li>
                    Coordinates meetings, conferences, and travel arrangements
                    with precision.
                  </li>
                  <li>
                    Assists in drafting, proofreading, and editing important
                    documents and correspondence.
                  </li>
                  <li>
                    Proficiently handles confidential information and maintains
                    discretion.
                  </li>
                  <li>
                    Supports marketing efforts by assisting in the creation of
                    marketing materials, digital campaigns, and social media
                    strategies.
                  </li>
                  <li>
                    Collaborates with the marketing team to execute marketing
                    plans, track performance metrics, and adjust strategies as
                    needed.
                  </li>
                  <li>
                    Assists in the organisation of events, webinars, and
                    promotional activities.
                  </li>
                  <li>
                    Provides exceptional administrative support to ensure the
                    executive team can focus on strategic initiatives.
                  </li>
                </ul>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={education} className="img-fluid cover" alt="educationimg" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience} className="img-fluid cover" alt="experience1img" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Skills </h3>
                <ul>
                  <li>
                    <b>Administrative Proficiency:</b> Exceptional organisational
                    skills, calendar management, and office coordination.
                  </li>
                  <li>
                    <b>Marketing Support:</b> Knowledgeable in digital marketing,
                    content creation, and social media management.
                  </li>
                  <li>
                    <b>Communication:</b> Strong written and verbal communication
                    skills.
                  </li>
                  <li>
                    <b>Team Collaboration:</b> Proven ability to work effectively in
                    cross-functional teams.
                  </li>
                  <li>
                    <b>Tech-Savvy:</b> Proficient in Microsoft Office Suite, CRM
                    software, and marketing tools.
                  </li>
                  <li>
                  <b>Adaptability:</b> Quick learner who can readily embrace new
                    technologies and strategies.
                  </li>
                </ul>
                <p>
                Marynelle Li's commitment to efficient executive support and her marketing acumen make her a versatile team member capable of contributing to both administrative and marketing initiatives. Her dedication to excellence and adaptability make her a valuable asset to IG-Smart Ltd.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-light">
        <TestimonialV2 />
      </div>
    </>
  );
};
