import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import banImg from "../../Assets/Images/teamDetail.jpg";
import team from "../../Assets/Images/team/team8.jpg";
import education from "../../Assets/Images/team/education.jpg";
import experience from "../../Assets/Images/team/experience.jpg";
import experience2 from "../../Assets/Images/team/exp2.jpg";
import clairTeam from "../../Assets/Images/team7-clair.jpg";
import clairTeam2 from "../../Assets/Images/team-Clair2.jpg";
import award from "../../Assets/Images/team/award.jpg";
import { TestimonialV2 } from "../../Components/TestimonialV2";
import { Loader } from "../../Components/Loader";

export const TeamDeatilClaire = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <section
        className={"inner-bnr banner-section"}
        style={{
          backgroundImage: `url(${banImg})`,
          backgroundPosition: "right",
          padding: "100px ",
        }}
      >
        <Container>
          <div className="banner_header">
            <h2 className="fw-bold mb-3 text-uppercase text-left">Team</h2>
          </div>
        </Container>
      </section>

      <section className="sec-padding">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={team} className="img-fluid" alt="teamimg" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3>Claire Griffin </h3>
                <div className="ct_card_name mt-2">
                Talent & Team Wellness Partner
                </div>
                <p>
                  Claire Griffin is a dedicated and compassionate professional
                  with a diverse background in talent acquisition, coaching,
                  mindfulness training, and executive resourcing. With over two
                  decades of experience, Claire brings a unique blend of skills
                  to her role as IG-Smart Ltd’s Associate Director of Executive
                  Resourcing & Team Wellbeing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                {/* <h3>About us</h3> */}
                <h3 className="mb-2">Professional Background </h3>
                <h6 className="fw-bold mb-2">
                  Founder Director - Mindfulness Web (Apr 2021 - Present)
                </h6>

                <ul>
                  <li>
                    Claire founded Mindfulness Web with a mission to reduce
                    stress levels, build resilience, and foster collaborative
                    working environments.
                  </li>
                  <li>
                    Specialises in coaching individuals and training groups in
                    evidence-based Mindfulness-Based Stress Reduction (MBSR) and
                    Mindfulness-Based Stress Resilience courses tailored for
                    workplaces.
                  </li>
                  <li>
                    Key Skills: Resilience, Training, Talent Development, Stress
                    Management, Mindfulness-Based Stress Reduction (MBSR),
                    Mindfulness Meditation.
                  </li>
                </ul>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={education} className="img-fluid cover" alt="education" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience} className="img-fluid cover" alt="experience" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Mindfulness </h3>
                <h6 className="fw-bold mb-2">
                  Mindfulness-Based Stress Reduction (MBSR) Teacher & Teacher
                  Trainer - The Mindful Academy (Jul 2015 - Jul 2022)
                </h6>
                <ul>
                  <li>
                    Served as a core member of the teaching team, offering Level
                    1 and Level 2 MBSR Teacher Training courses and CPD
                    Masterclasses.
                  </li>
                  <li>
                    Conducted training both in-person and online via Zoom,
                    reaching a diverse audience.
                  </li>
                  <li>
                    Developed expertise in mindfulness teaching and supervision.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h6 className="fw-bold mb-2">
                  Executive Search Consultant, Coach & Mindfulness Trainer -
                  Claire Griffin Talent Ltd (Jan 2010 - Apr 2021)
                </h6>
                <ul>
                  <li>
                    Founded and managed her own executive search-based Talent
                    Acquisition consultancy, catering to digital, mobile, and
                    data industries.
                  </li>
                  <li>
                    Transitioned the business to include coaching and
                    mindfulness training, offering holistic solutions.
                  </li>
                  <li>
                    Worked with organisations of all sizes, from start-ups to
                    multinationals.
                  </li>
                  <li>
                    Became a certified Personal Performance Coach and Small
                    Business Coach.
                  </li>
                </ul>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience2} className="img-fluid cover" alt="experience2" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={award} className="img-fluid cover" alt="award" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h6 className="fw-bold mb-2">
                  Associate Director Business Development - IG-SMART Ltd (Jan
                  2019 - Mar 2021)
                </h6>
                <ul>
                  <li>
                    Played a key role in transitioning a Data Privacy & Cyber
                    Security consultancy to success in the private sector.
                  </li>
                  <li>
                    Established and executed new business strategies, systems,
                    and processes.
                  </li>
                  <li>
                    Achieved record turnover levels and commercial sector
                    dominance.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h6 className="fw-bold mb-2">
                  Talent Lead - Profusion (May 2017 - Jun 2018)
                </h6>
                <ul>
                  <li>
                    Acted as an Interim Talent Lead for a Data Science &
                    Multi-Channel Marketing agency, overseeing talent
                    acquisition during a period of rapid transformation.
                  </li>
                  <li>
                    Collaborated with company leadership to implement a
                    values-based hiring strategy.
                  </li>

                  <li>
                    <dl className="mt-3">
                      <dt style={{ color: "#163a6c" }}>
                        <b>
                          Senior Executive Search Consultant - IMR Executive
                          (Sep 2007 - Dec 2009)
                        </b>
                      </dt>
                      <dd className="ps-3 mt-2 text-dark">
                        Specialised in executive search for the Mobile and
                        Digital sectors, working on senior appointments across
                        various industries.
                      </dd>
                    </dl>
                  </li>

                  <li>
                    <dl className="mt-3">
                      <dt style={{ color: "#163a6c" }}>
                        <b>
                          Consultant - Exposure Career Network, Spain (Jun 2006
                          - Jul 2007)
                        </b>
                      </dt>
                      <dd className="ps-3 mt-2 text-dark">
                        Consulted for an online recruitment network, focusing on
                        Sales and Marketing roles in Spain and Gibraltar.
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={clairTeam} className="img-fluid cover" alt="clairTeam" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={clairTeam2} className="img-fluid cover" alt="clairTeam2" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <ul>
                  <li>
                    <dl className="mt-3">
                      <dt style={{ color: "#163a6c" }}>
                        <b>
                          Account Director - Andalucia.com/Andalucia Web
                          Solutions (Sep 2004 - Jun 2006)
                        </b>
                      </dt>
                      <dd className="ps-3 mt-2 text-dark">
                        Managed display advertising sales and web design
                        projects for a prominent English/Spanish website in
                        Southern Spain.
                      </dd>
                    </dl>
                  </li>

                  <li>
                    <dl className="mt-3">
                      <dt style={{ color: "#163a6c" }}>
                        <b>
                          Managing Director - Griffin James Ltd (Sep 1998 - Oct
                          2002)
                        </b>
                      </dt>
                      <dd className="ps-3 mt-2 text-dark">
                        Specialised in recruitment and selection of account
                        handlers and creatives for marketing, sales promotion,
                        online marketing, and advertising agencies.
                      </dd>
                    </dl>
                  </li>

                  <li>
                    <dl className="mt-3">
                      <dt style={{ color: "#163a6c" }}>
                        <b>
                          Director - Churchill Mowland Associates (1994 - 1998)
                        </b>
                      </dt>
                      <dd className="ps-3 mt-2 text-dark">
                        Operated a recruitment consultancy specialising in
                        client services and planning roles for the Direct
                        Marketing and Sales Promotion agency market.
                      </dd>
                    </dl>
                  </li>
                </ul>
                <b>
                  Claire's extensive experience in executive resourcing,
                  coaching, and mindfulness training uniquely positions her to
                  enhance team well-being, foster a collaborative work
                  environment, and support the development of the executive
                  team. Her dedication to promoting mental and emotional
                  well-being in the workplace makes her a valuable asset to any
                  organisation. Claire is known for her ability to create
                  positive, transformational change in individuals and teams
                  alike.
                </b>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-light">
        <TestimonialV2 />
      </div>
    </>
  );
};
