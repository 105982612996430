import React from 'react';
import loader from '../Assets/Images/loaderDouble.gif';

export const Loader = () => {
  return (
    <div className='cstm_loader'>
        <img  loading="lazy"  width="" height=""   src={loader} alt='loader' />
    </div>
  )
}
