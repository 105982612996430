import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import banImg from "../../Assets/Images/teamDetail.jpg";
import team from "../../Assets/Images/team/team1.jpeg";
import education from "../../Assets/Images/team/education.jpg";
import experience from "../../Assets/Images/team/experience.jpg";
import experience2 from "../../Assets/Images/team/exp2.jpg";
import award from "../../Assets/Images/team/award.jpg";
import { TestimonialV2 } from "../../Components/TestimonialV2";
import { Loader } from "../../Components/Loader";

export const TeamDetail = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <section
        className={"inner-bnr banner-section"}
        style={{
          backgroundImage: `url(${banImg})`,
          backgroundPosition: "right",
          padding: "100px ",
        }}
      >
        <Container>
          <div className="banner_header">
            <h2 className="fw-bold mb-3 text-uppercase text-left">Team</h2>
          </div>
        </Container>
      </section>

      <section className="sec-padding">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={team} className="img-fluid" alt="team" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                {/* <h3>About us</h3> */}
                <h3>Michael Abtar </h3>
                <div className="ct_card_name mt-2">
                  CEO and Founder of Award-Winning Data Privacy
                </div>
                <div className="team-designation">
                Strategic Board & Government Advisor | International Keynote Speaker | Visionary Team Leader
                </div>
                <p>
                  Michael Abtar is a highly accomplished professional with a
                  wealth of experience and a track record of excellence in the
                  fields of data privacy, governance, quality, cyber security,
                  and clinical systems safety. As the CEO and Founder of a
                  multi-award-winning consultancy, Michael has consistently
                  delivered exceptional results and innovative solutions to a
                  diverse range of clients. His qualifications, extensive
                  experience, and notable awards underscore his exceptional
                  expertise and leadership in the industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                {/* <h3>About us</h3> */}
                <h3 className="mb-2">Qualifications </h3>
                <p>
                  Michael holds a Bachelor of Laws with Honours, which he
                  completed at Kingston University with an Upper Second-Class
                  degree. His deep understanding of legal principles forms the
                  foundation of his approach to data privacy and governance. He
                  further honed his legal skills by completing a Post Graduate
                  Diploma in Law (Legal Practice Course) at the College of Law
                  in Bloomsbury in 2006. In recognition of his specialisation in
                  data protection, he is also a Certified Data Protection
                  Officer.
                </p>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={education} className="img-fluid cover" alt="education" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience} className="img-fluid cover" alt="experience" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Experience </h3>
                <p>
                  With over 15 years of experience as the CEO and Founder of a
                  leading consultancy, Michael Abtar has played a pivotal role
                  in shaping the landscape of data privacy, cyber security, and
                  governance. His notable achievements include
                </p>
                <ul>
                  <li>
                    Leading the legal and information governance workstreams for
                    the development of a nationwide COVID-19 contact tracing
                    App, contributing to public health efforts during a critical
                    time.
                  </li>
                  <li>
                    Spearheading global GDPR compliance implementation programs
                    for major companies with extensive consumer databases,
                    ensuring data protection and privacy standards are met on a
                    global scale.
                  </li>
                  <li>
                    Providing training around the world to thousands of
                    individuals in data protection, privacy, information
                    governance, and cyber security, including senior executives,
                    lawyers, and data protection officers.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <ul>
                  <li>
                    Delivering keynote addresses and moderating plenary
                    discussions at global conferences with governments,
                    regulators, and major technology companies, including
                    Google, Microsoft, Facebook, and Thales. These engagements
                    showcase his thought leadership and strategic insights on
                    data privacy and governance.
                  </li>
                  <li>
                    Chairing London-wide Information Governance and Freedom of
                    Information Forums, demonstrating his leadership in
                    promoting good governance practices.
                  </li>
                  <li>
                    Simultaneously occupying multiple key roles within a
                    world-renowned organisation, including Data Protection
                    Officer, Data Quality and Payment by Results Manager,
                    Information Governance Manager, Information Security
                    Manager, and IT Training Manager, showcasing his versatility
                    and expertise.
                  </li>
                  <li>
                    Advising a wide range of clients, from start-ups to global
                    enterprises, local authorities, hospitals, charities, and
                    government organisations.
                  </li>
                  <li>
                    Successfully leading programs and projects for FTSE 100 and
                    Fortune 500 companies, ensuring data security, privacy, and
                    governance compliance.
                  </li>
                </ul>
                <p>
                  Before establishing his consultancy, Michael practiced law
                  across the Southeast of England, including at prestigious
                  institutions like the Royal Courts of Justice and Central
                  Criminal Court (Old Bailey).
                </p>
              </div>
            </div>
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={experience2} className="img-fluid cover" alt="experience2" />
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padding-bottom">
        <div className="container">
          <div className="complex-chng-des chng-des-md content_card">
            <div className="cmpx-img-card simple_Img team-img">
              <img  loading="lazy"  width="" height=""   src={award} className="img-fluid cover" alt="award" />
            </div>
            <div className="cmpx-chng-des">
              <div
                className="ltd-case-description custom-list"
                style={{ gap: 0 }}
              >
                <h3 className="mb-2">Awards </h3>

                <p>
                  Awards Michael's dedication and contributions have earned him
                  recognition as one of the CEO Today Top 100 UK CEOs for four
                  consecutive years, in 2020, 2021, 2022, and 2023. These
                  prestigious awards acknowledge his exceptional leadership and
                  outstanding achievements in the field.
                </p>
                <p>
                  As the CEO and Founder of a multi-award-winning consultancy,
                  Michael Abtar continues to drive innovation and excellence in
                  data privacy, cyber security, and governance. His commitment
                  to helping organisations navigate complex regulatory
                  landscapes, protect sensitive information, and deliver
                  training globally has made him a respected leader in the
                  industry. Michael's qualifications, extensive experience, and
                  accolades underscore his unwavering dedication to ensuring the
                  highest standards of data protection and privacy for his
                  clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-light">
        <TestimonialV2 />
      </div>
    </>
  );
};
