import React from "react";
import { Container } from "react-bootstrap";
import Banner from "../../../Components/Banner";
import SubmitRFP from "../../../Components/SubmitRFP";
import { AI_PRIVACY_CONSULTATION } from "../../../services/Url";
import { useCustomGet } from "../../../services/Service";
import { TestimonialV2 } from "../../../Components/TestimonialV2";
import { Loader } from "../../../Components/Loader";
import ServiceSlider from "../../../Components/ServiceSlider";

const AIPrivacyConsultation = () => {
  const { data, isLoading } = useCustomGet(AI_PRIVACY_CONSULTATION);

  const bannerContent = {
    title: `<h1>${data?.ai_banner_main_heading}</h1>`,
    cardTitle:data?.ai_banner_heading,
    cardPara:data?.ai_banner_content,
  }

  return (
    <>
      {
        isLoading ? 
        <Loader /> :''
      }
      <Banner bannerCnt={bannerContent} banImg={data?.ai_banner_image} />
      <section className=" bg-light service-trusted-by">
          <Container>
           <ServiceSlider />
           </Container>
        </section>
      <div className="internal-service-page" id="record-mang-service">
      
        <section
          className="award-sec sec-padding-top"
          style={{ backgroundColor: "#fff" }}
        >
          <Container>  
          <h2 className="sec-heading">
              {data?.why_ai_heading}
            </h2>         
            <div className="complex-chng-des chng-des-md">
              <div className="cmpx-img-card mt-0 img_design">
                <img  loading="lazy"  width="" height=""   src={data?.why_ai_box_image} alt="why_ai_box_image" />
              </div>
              <div className="cmpx-chng-des ">
                <div className="ltd-case-description mt-md-4 mt-3">
                  <div className="custom-list"
                    dangerouslySetInnerHTML={{__html: data?.why_ai_box_content}}
                  >             
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section
          className="award-sec sec-padding-top"
          style={{ backgroundColor: "#fff" }}
        >
          <Container>
            <h2 className="sec-heading">
              {data?.our_ai_heading}
            </h2>
            <div className="complex-chng-des chng-des-md reverse">
              <div className="cmpx-chng-des ">               
                <div className="ltd-case-description mt-md-4 mt-3">
                  <div className="custom-list"
                    dangerouslySetInnerHTML={{__html: data?.our_ai_content}}
                  >                   
                  </div>
                </div>
              </div>
              <div className="cmpx-img-card mt-0  img_design">
                <img  loading="lazy"  width="" height=""   src={data?.our_ai_image} alt="our_ai_image" />
              </div>
            </div>
          </Container>
        </section>
        <section
          className="award-sec sec-padding"
          style={{ backgroundColor: "#fff" }}
        >
          <Container>
            <h2 className="sec-heading mb-4">
                {data?.ai_approach_heading}
            </h2>
            <div className="complex-chng-des">
              <div className="cmpx-img-card mt-0  img_design">
                <img  loading="lazy"  width="" height=""   src={data?.ai_approach_image} alt="ai_approach_image" />
              </div>
              <div className="cmpx-chng-des custom-list "
                dangerouslySetInnerHTML={{__html: data?.ai_approach_content}}
              >
              </div>
            </div>
          </Container>
        </section>

        <section
          className="award-sec sec-padding-bottom"
          style={{ backgroundColor: "#fff" }}
        >
          <Container>
            <h2 className="sec-heading">
              {data?.ai_benifit_heading}
            </h2>
            <div className="complex-chng-des reverse">
              <div className="cmpx-chng-des custom-list"
                dangerouslySetInnerHTML={{__html:data?.ai_benifit_content}}
              >                               
              </div>
              <div className="cmpx-img-card mt-0  img_design">
                <img  loading="lazy"  width="" height=""   src={data?.ai_benifit_image} alt="ai_benifit_image" />
              </div>
            </div>
          </Container>
        </section>
        <section
          className="award-sec sec-padding"
          style={{ backgroundColor: "#fff" }}
        >
          <Container>
            <h2 className="sec-heading mb-4">
                {data?.ai_empower_heading}
            </h2>
            <div className="complex-chng-des">
              <div className="cmpx-img-card mt-0  img_design">
                <img  loading="lazy"  width="" height=""   src={data?.ai_empower_image} alt="ai_empower_image" />
              </div>
              <div className="cmpx-chng-des custom-list ">
              <div
                dangerouslySetInnerHTML={{__html: data?.ai_empower_content}}
              >
               
              </div>
              <b>{data?.ai_contact_heading}</b>
              </div>
            </div>
          </Container>
        </section>
       <div className="bg-light">
       <TestimonialV2 />
       </div>
        <SubmitRFP />
      </div>
    </>
  );
};
export default AIPrivacyConsultation;
