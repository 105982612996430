import React, { useState } from "react";
import Banner from "../Components/Banner";
import { Container } from "react-bootstrap";
import hexa_main from "../Assets/Images/hexa_main.png";
import Team from "../Components/Team";
import mainSer from "../Assets/Images/mainService.png";
import compliance from "../Assets/Images/compliance.png";
import compliance2 from "../Assets/Images/compliance2.png";
import Governance from "../Assets/Images/Governance.png";
import Governance2 from "../Assets/Images/Governance2.png";
import Privacy from "../Assets/Images/Privacy.png";
import Privacy2 from "../Assets/Images/Privacy2.png";
import Quality from "../Assets/Images/Quality.png";
import Quality2 from "../Assets/Images/Quality2.png";
import Resilience from "../Assets/Images/Resilience.png";
import Resilience2 from "../Assets/Images/Resilience2.png";
import Safety from "../Assets/Images/Safety.png";
import Safety2 from "../Assets/Images/Safety2.png";
import { Link } from "react-router-dom";
import ServiceSlider from "../Components/ServiceSlider";

const MainService = () => {
  const [hexaContent, setHexaContent] = useState(1);

  var bannerContent = {
    title1: "Penetration testing",
    title2: "Services Sample page",
    cardTitle:
      "Simplified advice and solutions. Engaging training. Industry approved.",
    cardPara:
      "Embed data protection, privacy, quality, security, and safety by design and default.",
  };

  const hexaHandler = (item) => {
    setHexaContent(item);
  };

  return (
    <>
      <Banner bannerCnt={bannerContent} banImg={mainSer} />
      <section className="main-service-head sec-padding">
        <Container>
          <div className="row">
            <div className="col-lg-6">
              <div className="main-service-sec-head">
                <h2 className="sec-heading">The Ig-Smart Way</h2>
                <p className="sub-heading">
                  Since 2005, Cyber-Duck’s award-winning process, people and
                  projects have empowered clients to grow beyond their
                  expectations.
                </p>
              </div>
              {hexaContent === 1 ? (
                <div className="main-service-sec-card">
                  <h4>TECHNOLOGY AND DATA ENABLERS</h4>
                  <p>
                    Fast-moving digital leaders are gaining an insurmountable
                    advantage over the competition—powered by cutting-edge
                    technologies and data-driven insights. Our digital
                    consulting team helps clients uncover, incubate, and deliver
                    game-changing digital businesses and products.
                  </p>
                </div>
              ) : hexaContent === 2 ? (
                <div className="main-service-sec-card">
                  <h4>TECHNOLOGY AND DATA ENABLERS 2</h4>
                  <p>
                    Fast-moving digital leaders are gaining an insurmountable
                    advantage over the competition—powered by cutting-edge
                    technologies and data-driven insights. Our digital
                    consulting team helps clients uncover, incubate, and deliver
                    game-changing digital businesses and products.
                  </p>
                </div>
              ) : hexaContent === 3 ? (
                <div className="main-service-sec-card">
                  <h4>TECHNOLOGY AND DATA ENABLERS 3</h4>
                  <p>
                    Fast-moving digital leaders are gaining an insurmountable
                    advantage over the competition—powered by cutting-edge
                    technologies and data-driven insights. Our digital
                    consulting team helps clients uncover, incubate, and deliver
                    game-changing digital businesses and products.
                  </p>
                </div>
              ) : hexaContent === 4 ? (
                <div className="main-service-sec-card">
                  <h4>TECHNOLOGY AND DATA ENABLERS 4</h4>
                  <p>
                    Fast-moving digital leaders are gaining an insurmountable
                    advantage over the competition—powered by cutting-edge
                    technologies and data-driven insights. Our digital
                    consulting team helps clients uncover, incubate, and deliver
                    game-changing digital businesses and products.
                  </p>
                </div>
              ) : hexaContent === 5 ? (
                <div className="main-service-sec-card">
                  <h4>TECHNOLOGY AND DATA ENABLERS 5</h4>
                  <p>
                    Fast-moving digital leaders are gaining an insurmountable
                    advantage over the competition—powered by cutting-edge
                    technologies and data-driven insights. Our digital
                    consulting team helps clients uncover, incubate, and deliver
                    game-changing digital businesses and products.
                  </p>
                </div>
              ) : (
                <div className="main-service-sec-card">
                  <h4>TECHNOLOGY AND DATA ENABLERS 6</h4>
                  <p>
                    Fast-moving digital leaders are gaining an insurmountable
                    advantage over the competition—powered by cutting-edge
                    technologies and data-driven insights. Our digital
                    consulting team helps clients uncover, incubate, and deliver
                    game-changing digital businesses and products.
                  </p>
                </div>
              )}
            </div>
            <div className="col-lg-6 my-auto ">
              <div className="hex_wpr">
                <div className="hex_wpr_inr">
                  <div
                    className={
                      hexaContent === 1 ? "hex hx_img1 active" : "hex hx_img1"
                    }
                    onClick={() => hexaHandler(1)}
                    onMouseOver={() => hexaHandler(1)}
                  >
                    <img  loading="lazy"  width="" height=""   src={compliance} alt="compliance" />
                    <img  loading="lazy"  width="" height=""   src={compliance2} className="hexa_img2" alt="compliance2" />
                  </div>
                  <div
                    className={
                      hexaContent === 2 ? "hex hx_img2 active" : "hex hx_img2"
                    }
                    onClick={() => hexaHandler(2)}
                    onMouseOver={() => hexaHandler(2)}
                  >
                    <img  loading="lazy"  width="" height=""   src={Safety} alt="Safety" />
                    <img  loading="lazy"  width="" height=""   src={Safety2} className="hexa_img2" alt="Safety2" />
                  </div>
                  <div
                    className={
                      hexaContent === 3 ? "hex hx_img3 active" : "hex hx_img3"
                    }
                    onClick={() => hexaHandler(3)}
                    onMouseOver={() => hexaHandler(3)}
                  >
                    <img  loading="lazy"  width="" height=""   src={Resilience} alt="Resilience" />
                    <img  loading="lazy"  width="" height=""   src={Resilience2} className="hexa_img2" alt="Resilience2" />
                  </div>
                  <div
                    className={
                      hexaContent === 4 ? "hex hx_img4 active" : "hex hx_img4"
                    }
                    onClick={() => hexaHandler(4)}
                    onMouseOver={() => hexaHandler(4)}
                  >
                    <img  loading="lazy"  width="" height=""   src={Quality} alt="Quality" />
                    <img  loading="lazy"  width="" height=""   src={Quality2} className="hexa_img2" alt="Quality2" />
                  </div>
                  <div
                    className={
                      hexaContent === 5 ? "hex hx_img5 active" : "hex hx_img5"
                    }
                    onClick={() => hexaHandler(5)}
                    onMouseOver={() => hexaHandler(5)}
                  >
                    <img  loading="lazy"  width="" height=""   src={Privacy} alt="Privacy" />
                    <img  loading="lazy"  width="" height=""   src={Privacy2} className="hexa_img2" alt="Privacy2" />
                  </div>
                  <div
                    className={
                      hexaContent === 6 ? "hex hx_img6 active" : "hex hx_img6"
                    }
                    onClick={() => hexaHandler(6)}
                    onMouseOver={() => hexaHandler(6)}
                  >
                    <img  loading="lazy"  width="" height=""   src={Governance} alt="Governance" />
                    <img  loading="lazy"  width="" height=""   src={Governance2} className="hexa_img2" alt="Governance2" />
                  </div>
                  <img  loading="lazy"  width="" height=""   className="ms-img" src={hexa_main} alt="img" />
                </div>
              </div>
            </div>
          </div>
          <ul className="main-service-sec-list">
            <li>Data and Analytics</li>
            <li>Digital Platform</li>
            <li>Artificial Intelligence</li>
            <li>Cybersecurity and Digital Risk</li>
            <li>Data and Analytics</li>
            <li>Digital Platform</li>
            <li>Artificial Intelligence</li>
            <li>Cybersecurity and Digital Risk</li>
          </ul>
        </Container>
      </section>
      <section className="mission-sec sec-padding">
        <Container>
          <h2 className="sec-heading">What is your Mission today?</h2>
          <div className="mission-cards">
            <div className="mission-card flex-column">
              <p>Harness the power of Data and Analytics</p>
              <div className="mission-active-card">
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
              </div>
            </div>
            <div className="mission-card flex-column">
              <p>Build a digital culture</p>
              <div className="mission-active-card">
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
              </div>
            </div>
            <div className="mission-card flex-column">
              <p>Modernize core technology</p>
              <div className="mission-active-card">
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
              </div>
            </div>
            <div className="mission-card flex-column">
              <p>Create stunning digital experiences</p>
              <div className="mission-active-card">
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
              </div>
            </div>
            <div className="mission-card flex-column">
              <p>Accelerate marketing & sales</p>
              <div className="mission-active-card">
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
              </div>
            </div>
            <div className="mission-card flex-column">
              <p>Optimize your operations</p>
              <div className="mission-active-card">
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
                <p>
                  <Link to="/">Ipsum Dolor Sit Amet</Link>
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="health-seo-sec sec-padding-top">
        <Container>
          <h2 className="sec-heading">Healthcare SEO Services</h2>
          <p className="sub-heading">
            We have years of experience optimising for Healthcare SEO and
            medical based searches. With health-related searches increasing
            around 20% year-on-year, it is clear that the online market for
            digital health services is still fast growing. From pharmacies, to
            private medical services, we’re able to create great results for
            health and medical brands. See how we helped Patient.co.uk develop
            their{" "}
            <a href='javascript:void(0)'>
              <span>Healthcare SEO campaign.</span>
            </a>
          </p>
        </Container>
      </section>
      <div className="main-ser-slider sec-padding-bottom">
        <ServiceSlider />
        <div className="btn_grp btn_center">
          <Link to={"/clientstory"} className="btn-design">
            SEE PATIENT.CO.UK CASE STUDY
          </Link>
        </div>
      </div>
      <section className="main-service-team-sec sec-padding-top greyBg">
        <Container>
          <Team title="Meet Our Team" />
        </Container>
      </section>
    </>
  );
};

export default MainService;
