import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { initGA, logPageView } from "../Components/GoogleAnalytics";
import { ScrollUp } from "../Components/ScrollUp";
import { MobileHeader } from "../Components/MobileHeader";


export const MainContainer = () => {
  return (
   <>
    <Header />
    <MobileHeader />
    <Outlet />
    <Footer />
    <ScrollUp />
   </>
  );
};
