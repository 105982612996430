import React from "react";
import { Container } from "react-bootstrap";
import Banner from "../Components/Banner";
import FAQ from "../Components/FAQ";

import courseVid from "../Assets/Images/courseVid.png";
import vidIcon from "../Assets/Images/videoIcon.svg";

import starStroke from "../Assets/Images/starStroke.svg";
import starFull from "../Assets/Images/starFull.svg";
import blueStar from "../Assets/Images/blueStar.svg";
import img1 from "../Assets/Images/image100.png";

import logo1 from "../Assets/Images/logo1.png";
import logo2 from "../Assets/Images/logo2.png";
import logo3 from "../Assets/Images/logo3.png";
import logo4 from "../Assets/Images/logo4.png";
import logo5 from "../Assets/Images/logo5.png";
import logo6 from "../Assets/Images/logo6.png";
import logo7 from "../Assets/Images/logo7.png";

import coursesBan from "../Assets/Images/coursesBan.png";

const Courses = () => {
  var bannerContent = {title1: 'LS is a simple', title2: 'and best industry.' ,
  cardTitle:
    "the printing and typesetting industry printing and typesetting industry.",
  cardPara:
    "the printing and typesetting industry printing and typesetting industry.",
  butnCtn1: "Request a proposal",
  butnCtn2: "Enroll today", 

};
  const arr = [1,1,1,1,1,1,1,1,1]

  return (
    <>
      <Banner bannerCnt={bannerContent} banImg={coursesBan} bannerclassName="course-banner"/>
      <section className="course-detail-sec">
        <Container>
          <div className="course-detail-box">
            <div className="course-info">
              <p>What you will learn? </p>
              <h6>The full  Process</h6>
            </div>
            <div className="course-info">
              <p>Duration</p>
              <h6>6 Months</h6>
            </div>
            <div className="course-info">
              <p>Commitment</p>
              <h6>5 hours per week</h6>
            </div>
            <div className="course-info">
              <p>Price</p>
              <h6>$ 3,250</h6>
            </div>
            <div className="course-info">
              <p>Format</p>
              <h6>Online, self-paced</h6>
            </div>
          </div>
        </Container>
      </section>
      <section className="build-career-sec sec-padding">
        <Container>
          <div className="build-career-description">
            <div className="custom-list">
              <h2 className="sec-heading">
                Build a rewarding career in 
              </h2>
              <ul>
                <li>Learn to think like a</li>
                <li>Build confidence in essential concepts</li>
                <li>Get job-ready skills and a professional portfolio</li>
              </ul>
            </div>
            <div className="video-section">
              <div className="video-img">
                <img  loading="lazy"  width="" height=""   src={courseVid} alt="courseVid" />
              </div>
              <div className="play-icon">
                <img  loading="lazy"  width="" height=""   src={vidIcon} alt="vidIcon" />
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="gold-stnd-edu-sec sec-padding">
      <Container>
        <h2 className="sec-heading">The gold standard in Lorem education</h2>
        <p className="sub-heading">
         consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
          ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        <div className="rating-review-cards">
          <div className="row">
            <div className="col-xl-4">
              <div className="review-wpr">
              <div className="rating-reviews-count">
                <div className="rating-stars">
                  <h2 className="sec-heading">4.9</h2>
                  <ul>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starFull} alt="starFull1" />
                    </li>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starFull} alt="starFull2" />
                    </li>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starFull} alt="starFull3" />
                    </li>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starFull} alt="starFull" />
                    </li>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starStroke} alt="starFull" />
                    </li>
                  </ul>
                </div>
                <div className="rating-platform">
                  <h6>Google</h6>
                  <p>(663 Rivews)</p>
                </div>
              </div>
              <div className="rating-reviews-count">
                <div className="rating-stars">
                  <h2 className="sec-heading">4.8</h2>
                  <ul>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starFull} alt="starFull" />
                    </li>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starFull} alt="starFull" />
                    </li>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starFull} alt="starFull" />
                    </li>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starFull} alt="starFull" />
                    </li>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starStroke} alt="starFull" />
                    </li>
                  </ul>
                </div>
                <div className="rating-platform">
                  <h6>Switch Up</h6>
                  <p>(663 Rivews)</p>
                </div>
              </div>
              <div className="rating-reviews-count">
                <div className="rating-stars">
                  <h2 className="sec-heading">4.7</h2>
                  <ul>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starFull} alt="starFull" />
                    </li>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starFull} alt="starFull" />
                    </li>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starFull} alt="starFull" />
                    </li>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starFull} alt="starFull" />
                    </li>
                    <li>
                      <img  loading="lazy"  width="" height=""   src={starStroke} alt="starFull" />
                    </li>
                  </ul>
                </div>
                <div className="rating-platform">
                  <h6>Course Report</h6>
                  <p>(663 Rivews)</p>
                </div>
              </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="row">
                <div className="col-md-6 pb-4">
                  <div className="review-description-card mx-auto">
                    <p>
                      Slate helps you see how many more days you need to work
                      to reach your financial goal.
                    </p>
                    <div className="review-person-profile">
                      <div className="profile-img">
                        <img  loading="lazy"  width="" height=""   src={img1} alt="img1" />
                      </div>
                      <div className="profile-desgination">
                        <h6>Regina Miles</h6>
                        <h6>Designer</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pb-4">
                  <div className="review-description-card mx-auto">
                    <p>
                      Slate helps you see how many more days you need to work
                      to reach your financial goal.
                    </p>
                    <div className="review-person-profile">
                      <div className="profile-img">
                        <img  loading="lazy"  width="" height=""   src={img1} alt="img11" />
                      </div>
                      <div className="profile-desgination">
                        <h6>Regina Miles</h6>
                        <h6>Designer</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pb-4">
                  <div className="review-description-card mx-auto">
                    <p>
                      Slate helps you see how many more days you need to work
                      to reach your financial goal.
                    </p>
                    <div className="review-person-profile">
                      <div className="profile-img">
                        <img  loading="lazy"  width="" height=""   src={img1} alt="person" />
                      </div>
                      <div className="profile-desgination">
                        <h6>Regina Miles</h6>
                        <h6>Designer</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pb-4">
                  <div className="review-description-card mx-auto">
                    <p>
                      Slate helps you see how many more days you need to work
                      to reach your financial goal.
                    </p>
                    <div className="review-person-profile">
                      <div className="profile-img">
                        <img  loading="lazy"  width="" height=""   src={img1} alt="person" />
                      </div>
                      <div className="profile-desgination">
                        <h6>Regina Miles</h6>
                        <h6>Designer</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
    
      <section className="what-you-learn-sec sec-padding">
        <Container>
          <h2 className="sec-heading">What you'll learn</h2>
          <p className="sub-heading">
           consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
          <br />
          <p className="sub-heading">
            To see the full syllabus, <a href=""><span>Request More Details</span></a>
          </p>
          
          <div className="module-cards">
          
          {
            arr?.map((item, i) => {
              return(
                  <div className="module-card mx-auto" key={i}>
                    <div className="module-head">
                      <img  loading="lazy"  width="" height=""   src={blueStar} alt="blueStar" />
                      <h6>Module 1</h6>
                    </div>
                    <h5>Introduction to UX design</h5>
                    <p>
                      More than 2 billion we people over countries use socibooks
                      we to stay in touch with friends.
                    </p>
                  </div>
              )
            })
          }
        </div>
         
          <div className="more-details">
            <p className="sub-heading">
              Request More Details To View The Syllabus
            </p>
            <button className="btn-design">Request more details</button>
          </div>
        </Container>
      </section>
      <section className="payment-plan-sec">
        <Container>
          <div className="payment-plan-details">
            <div className="payment-plan-date">
              <h6>Next course commences:</h6>
              <h4>7 March 2023</h4>
              <h6 className="header-yellow">Limited Space Available</h6>
              <h6>Payment plans avaialble</h6>
              <p>
                Pay €542 per month over 6 months. 0% interest. Speak to an
                advisor to learn more.
              </p>
              <button className="btn-design">Speak to an advisor</button>
            </div>
            <div className="payment-plan-offer">
              <h4>Early bird price until 21 February 2023</h4>
              <h2 className="sec-heading">$2,475</h2>
              <h3>$2475</h3>
              <h5>Payment plans avaialble</h5>
              <button className="btn-design">Request more details</button>
              <p className="sub-heading">
                or <a href=""><span>enrol today</span></a>
              </p>
            </div>
          </div>
        </Container>
      </section>
      <section className="graduate-jobs-sec sec-padding ">
        <Container>
          <h2 className="sec-heading">Our graduates get jobs they love</h2>
          <p className="sub-heading">
          We’ve help thousands of students to seamlessly navigate governance, risk, security & compliance roles and responsibilities
          </p>
          <ul className="companies-logo">
            <li>
              <img  loading="lazy"  width="" height=""   src={logo1} alt="logo1" />
            </li>
            <li>
              <img  loading="lazy"  width="" height=""   src={logo2} alt="logo2" />
            </li>
            <li>
              <img  loading="lazy"  width="" height=""   src={logo3} alt="logo3" />
            </li>
            <li>
              <img  loading="lazy"  width="" height=""   src={logo4} alt="logo4" />
            </li>
            <li>
              <img  loading="lazy"  width="" height=""   src={logo5} alt="logo5" />
            </li>
            <li>
              <img  loading="lazy"  width="" height=""   src={logo6} alt="logo6" />
            </li>
            <li>
              <img  loading="lazy"  width="" height=""   src={logo7} alt="logo7" />
            </li>
          </ul>
          <div className="request-for-more-details">
            <div className="custom-list">
              <h2 className="sec-heading">Request more details</h2>
              <p className="sub-heading">Submit your contact details to get:</p>
              <ul>
                <li>The full course syllabus</li>
                <li>Your questions answered, by phone or email</li>
                <li>Helpful career advice with no obligations</li>
              </ul>
              <p className="sub-heading">
                Or if you're happy to get started, you can{" "}
                <a href=""><span>enrol today.</span></a>
              </p>
            </div>
            <form action="" className="request-form">
              <input
                type="text"
                id="fullname"
                name="fullname"
                placeholder="Full Name"
              />
              <input type="email" id="email" placeholder="example@gmail.com" />
              <input type="tel" id="phn" placeholder="Phone No." />
              <input type="text" id="subject" placeholder="Subject" />
              <textarea
                name="message"
                id="Message"
                placeholder="Message"
                row="12"
              />
              <button className="btn-design" type="submit">
                Request more details
              </button>
            </form>
          </div>
        </Container>
      </section>
      <section className="upcomming-courses-sec sec-padding ">
        <Container>
          <h2 className="sec-heading">Upcomming Courses</h2>
          <p className="sub-heading">
           consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
          <div className="upcomming-courses-cards">
            <div className="upcomming-courses-card">
              <div className="course-description">
                <h5>Noble Violence in Early Modern Europe</h5>
                <p>
                  This day school brings together 4 of the principal experts on
                  the topic of noble honour and duelling in early modern Britain
                  and Europe, and places elite violence in England into
                  comparative perspective. Join us in Oxford or watch online.
                </p>
              </div>
              <div><button className="btn-design">Available</button></div>
            </div>
          </div>
          <div className="upcomming-courses-cards">
            <div className="upcomming-courses-card">
              <div className="course-description">
                <h5>Noble Violence in Early Modern Europe</h5>
                <p>
                  This day school brings together 4 of the principal experts on
                  the topic of noble honour and duelling in early modern Britain
                  and Europe, and places elite violence in England into
                  comparative perspective. Join us in Oxford or watch online.
                </p>
              </div>
              <div><button className="btn-design">Available</button></div>
            </div>
          </div>
          <div className="upcomming-courses-cards">
            <div className="upcomming-courses-card">
              <div className="course-description">
                <h5>Noble Violence in Early Modern Europe</h5>
                <p>
                  This day school brings together 4 of the principal experts on
                  the topic of noble honour and duelling in early modern Britain
                  and Europe, and places elite violence in England into
                  comparative perspective. Join us in Oxford or watch online.
                </p>
              </div>
              <div><button className="btn-design">Available</button></div>
            </div>
          </div>
        </Container>
      </section>
      <FAQ/>
    </>
  );
};

export default Courses;
