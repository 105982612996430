import React, { useState } from "react";
import InnerBanner from "../Components/InnerBanner";
import mail from "../Assets/Images/contactMsg.png";
import location from "../Assets/Images/contactLoc.png";
import contact from "../Assets/Images/contactPhn.png";
import contactBan from "../Assets/Images/contact.jpg";
import buisnessTime from "../Assets/Images/buisnessTime.jpg";
import {
  CONTACT_FORM_BASE_URL,
  CONTACT_FORM_LAST_URL,
  CONTACT_PAGE_FORM_URL,
} from "../services/Url";
import { useFormik } from "formik";
import * as Yup from "yup";
import { contactFormApi } from "../services/Service";
import { Loader } from "../Components/Loader";

export const Contact = () => {
  const [isLoading, setIsLoading] = useState(false)
  var bannerContent = {
    cardTitle: "Contact",
    cardPara: "",
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      userEmail: "",
      phone: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .max(25, "Must be 25 characters or less")
        .required("Must be Required Full Name"),
      subject: Yup.string().required("Must be Required Subject"),
      phone: Yup.string()
        .matches(/^[0-9]{10,14}$/, "Please Enter Valid Number")
        .required("Phone Number is required"),
      userEmail: Yup.string()
        .email("Invalid email address")
        .required("Must be Required Email Address"),
      message: Yup.string().required("Must be Required Message"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("your-name", values?.fullName);
      formData.append("your-email", values?.userEmail);
      formData.append("your-number", values?.phone);
      formData.append("text-352", values?.subject);
      formData.append("your-message", values?.message);
      let url = CONTACT_FORM_BASE_URL + CONTACT_PAGE_FORM_URL + CONTACT_FORM_LAST_URL;
      // setError(false)
      contactFormApi(url, formData, setIsLoading);

      formik.resetForm();
    },
  });

  return (
    <>
    {isLoading ? <Loader /> : ""}
      <InnerBanner bannerCnt={bannerContent} banImg={contactBan} />
      <div
        className="internal-service-page contact-page"
        id="cyber-essen-certi-service"
      >
        <section className="contact-card-sec sec-padding">
          <div className="container">
            <div className="sec-heading-wpr text-center">
              <h2 className="sec-heading">Contact us</h2>
            </div>

            <div className="contact-cards">
              <a
                href="https://www.google.com/maps/place/Ground+Floor,+20,+22+Wenlock+Rd,+London+N1+7GU,+UK/data=!4m2!3m1!1s0x48761b5f57e02493:0x19f83a9ed6b7a46e?sa=X&ved=2ahUKEwjT2IqFpbmBAxVDyzgGHWXrBDoQ8gF6BAgTEAA&ved=2ahUKEwjT2IqFpbmBAxVDyzgGHWXrBDoQ8gF6BAgUEAI"
                className="contact-card"
                data-aos="zoom-in"
              >
                <div className="contact-img">
                  <img  loading="lazy"  width="" height=""   src={location} alt="location" />
                </div>
                <h4 className="contact-heading">Office Location</h4>
                <p className="contact-desc">20-22 Wenlock Road, LondonN1 7TA</p>
              </a>
              <a
                href="mailto:info@ig-smart.com"
                className="contact-card"
                data-aos="zoom-in"
              >
                <div className="contact-img">
                  <img  loading="lazy"  width="" height=""   src={mail} alt="mail" />
                </div>
                <h4 className="contact-heading">Email Address</h4>
                <p className="contact-desc">info@ig-smart.com</p>
              </a>
              <a
                href="tel:+44 (0) 20 7167 4268"
                className="contact-card"
                data-aos="zoom-in"
              >
                <div className="contact-img">
                  <img  loading="lazy"  width="" height=""   src={contact} alt="contact" />
                </div>
                <h4 className="contact-heading">Phone Number</h4>
                <p className="contact-desc">+44 (0) 20 7167 4268</p>
              </a>
            </div>
          </div>
        </section>

        <section className="contact-form-sec sec-padding-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-heading-wpr custom-list">
                  <h2 className="sec-heading text-left">OFFICE HOURS</h2>
                  <div className="business-time">
                    <img  loading="lazy"  width="" height=""   src={buisnessTime} alt="buisnessTime" className="img-fluid" />
                  </div>
                  <table className="table table-bordered text-center table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Day</th>
                        <th scope="col">Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Monday</th>
                        <td>9.00 to 17.00</td>
                      </tr>
                      <tr>
                        <th scope="row">Tuesday</th>
                        <td>9.00 to 17.00</td>
                      </tr>
                      <tr>
                        <th scope="row">Wednesday</th>
                        <td>9.00 to 17.00</td>
                      </tr>
                      <tr>
                        <th scope="row">Thursday</th>
                        <td>9.00 to 17.00</td>
                      </tr>
                      <tr>
                        <th scope="row">Friday</th>
                        <td>9.00 to 17.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-form">
                  <form className="cstm-form-rw" onSubmit={formik.handleSubmit}>
                    <div className="fx-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.fullName}
                        name="fullName"
                      />
                      {formik.touched.fullName && formik.errors.fullName ? (
                        <p className="required mt-1 mb-0">
                          {formik.errors.fullName}
                        </p>
                      ) : null}
                    </div>
                    <div className="fx-6">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.userEmail}
                        name="userEmail"
                      />
                      {formik.touched.userEmail && formik.errors.userEmail ? (
                        <p className="required mt-1 mb-0">
                          {formik.errors.userEmail}
                        </p>
                      ) : null}
                    </div>
                    <div className="fx-6">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Phone Number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        name="phone"
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <p className="required mt-1 mb-0">
                          {formik.errors.phone}
                        </p>
                      ) : null}
                    </div>
                    <div className="fx-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Subject"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.subject}
                        name="subject"
                      />
                      {formik.touched.subject && formik.errors.subject ? (
                        <p className="required mt-1 mb-0">
                          {formik.errors.subject}
                        </p>
                      ) : null}
                    </div>
                    <div className="fx-12">
                      <textarea
                        placeholder="Message"
                        rows={8}
                        className="form-control mw-100"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                        name="message"
                      ></textarea>
                      {formik.touched.message && formik.errors.message ? (
                        <p className="required mt-1 mb-0">
                          {formik.errors.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="btn-wpr mt-sm-4 mt-2">
                      <button
                        type="submit"
                        className="btn-design d-inline-flex"
                      >
                        Send a Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
