import React, { useEffect, useState } from "react";
import fbIcon from "../Assets/Images/fbIcon.svg";
import linkedIn from "../Assets/Images/linkedIn.svg";
import twitter from "../Assets/Images/twitter.svg";
import CookieConsent from "react-cookie-consent";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  useEffect(() => {
    // Check if the code is running in the browser environment
    if (typeof window !== 'undefined') {
      // Import the component dynamically
      import('react-cookie-consent').then((module) => {
        setShowCookieConsent(true);
      });
    }
  }, []);

  return (
    <footer className="footer-section">
       {showCookieConsent && (<CookieConsent
        location="bottom"
        enableDeclineButton
        flipButtons
        buttonText="Allow all cookies"
        declineButtonText="Use necessary cookies only"
        cookieName="myAwesomeCookieName3"
        expires={999}
      >
        <div className="cookie">
          <div className="cookie_inr">
            <div className="cookie_ctnt">
              <h5>This website uses cookies</h5>
              <p>
                Unlock Your Tailored Insights: Enhance Your Experience and
                Expertise! 🚀 Embrace a personalised journey through data
                privacy, cyber security, compliance, and technology. Allow all
                cookies for exclusive, industry-specific insights that cater to
                your interests. Join us in fostering a safer digital world. Tap
                'Allow' for a smarter online adventure! Your data will be
                respected and processed in accordance with our{" "}
                <NavLink to="/privacypolicy" className="color-secondry">
                  Privacy Policy
                </NavLink>{" "}
                and{" "}
                <NavLink to="/cookie" className="color-secondry">
                  Cookie Policy
                </NavLink>
                .​
              </p>
            </div>
          </div>
        </div>
      </CookieConsent>)}
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <p className="fz-28">About Us</p>
            <ul className="ftr-service-list">
              <li>
                <NavLink to="/ourvalue">Our Values & Mission</NavLink>
              </li>
              <li>
                <NavLink to="/ourteam">Our Team</NavLink>
              </li>
              <li>
                <NavLink to="/oursdg">Our SDG's</NavLink>
              </li>
              <li>
                <NavLink to="/career">Careers</NavLink>
              </li>
              <li>
                <NavLink to="/investor">Investors</NavLink>
              </li>
              <li>
                <NavLink to="/media-enquiry">Media Enquiries</NavLink>
              </li>
              <li>
                <NavLink to="/speaker-enquiry">Speaker Enquiries</NavLink>
              </li>
              <li>
                <NavLink to="/legal-and-compliance">Legal & Compliance</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact</NavLink>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-sm-6">
            <p className="fz-28">Our Services</p>
            <ul className="ftr-service-list">
              <li>
                <NavLink to="/gdpr-auditors-services">
                  GDPR Consultancy services
                </NavLink>
              </li>
              <li>
                <NavLink to="/gdpr-nhs-training">GDPR Training Online</NavLink>
              </li>
              <li>
                <NavLink to="/nhs-information-governance-consultancy">
                  NHS DSP Toolkit Compliance services
                </NavLink>
              </li>
              <li>
                <NavLink to="/dpo-services">Outsourced DPO services</NavLink>
              </li>
              <li>
                <NavLink to="/iso-27001-audit-service">
                  ISO 27001 Internal Audit services
                </NavLink>
              </li>
              <li>
                <NavLink to="/iso-27001-certification-services">
                  ISO 27001 Certification services
                </NavLink>
              </li>
              <li>
                <NavLink to="/cyber-essentials-certification-services">
                  Cyber Essentials Certification services
                </NavLink>
              </li>
              <li>
                <NavLink to="/cyber-security-consultancy-services">
                  Cyber Security Consultancy services
                </NavLink>
              </li>
              <li>
                <NavLink to="/data-protection-consultancy-2">
                  Data Protection Consultancy services
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-sm-6">
            <p className="fz-28">Our Courses</p>
            <ul className="ftr-service-list">
              <li>
                <NavLink to="/caldicott-guardian-training">
                  Caldicott Guardian Training
                </NavLink>
              </li>
              <li>
                <NavLink to="/cyber-security-training">
                  Cyber Security Risk Awareness
                </NavLink>
              </li>
              <li>
                <NavLink to="/data-assest-owner-training-online">
                  Data Asset Owner Training
                </NavLink>
              </li>
              <li>
                <NavLink to="/data-protection-officer-training-online">
                  {" "}
                  Data Protection Officer Training
                </NavLink>
              </li>
              <li>
                <NavLink to="/data-protection-officer-training-for-health-and-socialcare">
                  {" "}
                  DPO Training for Health and Social Care
                </NavLink>
              </li>
              <li>
                <NavLink to="/data-protection-officer-training-for-pharma">
                  DPO Training for Pharma
                </NavLink>
              </li>
              <li>
                <NavLink to="/data-privacy-training-for-pharma">
                  Data Privacy Training for Pharma
                </NavLink>
              </li>
              <li>
                <NavLink to="/ig-gdpr-training">GDPR Training</NavLink>
              </li>
              <li>
                <NavLink to="/store">Buy Course</NavLink>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-sm-6">
            <p className="fz-28">Get In Touch</p>
            <ul className="ftr-get-list">
              <li>
                <NavLink
                  to={
                    "https://www.google.com/maps/place/20,+22+Wenlock+Rd,+London+N1+7GU,+UK/data=!4m2!3m1!1s0x48761b5f57e02493:0x3b1943bd88438f58?sa=X&ved=2ahUKEwiFrf-Uhrb_AhULRmwGHamWBLQQ8gF6BAgREAI"
                  }
                >
                  20-22 Wenlock Road, London N1 7TA
                </NavLink>
              </li>
              <li>
                <a href="tel:+44 (0) 20 7167 4268">+44 (0) 20 7167 4268</a>
              </li>
              <li>
                <a href="mailto: info@ig-smart.com">info@ig-smart.com</a>
              </li>
            </ul>
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.0127265867472!2d-0.0965643231303309!3d51.531326408954754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b5f57e02493%3A0x3b1943bd88438f58!2s20%2C%2022%20Wenlock%20Rd%2C%20London%20N1%207GU%2C%20UK!5e0!3m2!1sen!2sin!4v1685613074391!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="IGS"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
      <div className="container">
          <div className="copy_inr">
            <div className="copy_inr_txt">
              IG Smart Ltd © 2023. All Rights Reserved.
              <NavLink to="/term-and-condition">Terms of Use</NavLink>,
              <NavLink to="/privacypolicy">Privacy Policy</NavLink>
              and <NavLink to="/cookie"> Cookies</NavLink>
            </div>
            <div className="social-wpr">
              <div>Follow Us On:</div>
              <ul className="social-icons">
                <li>
                  <NavLink to="https://www.facebook.com/igsmartuk/">
                    <img  loading="lazy"  width="" height=""   src={fbIcon} alt={'fbIcon'} />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="https://www.linkedin.com/company/ig-smart/">
                    <img  loading="lazy"  width="" height=""   src={linkedIn} alt={'linkedIn'} />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="https://twitter.com/ig_smart?lang=en">
                    <img  loading="lazy"  width="" height=""   src={twitter} alt={'twitter'} />
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
