import React from "react";
import Banner from "../Components/Banner";
import { Container } from "react-bootstrap";
import Team from "../Components/Team";

import arrow from "../Assets/Images/ArrowRight.svg";

import icon1 from "../Assets/Images/serIcon1.png";
import icon2 from "../Assets/Images/serIcon2.png";
import icon3 from "../Assets/Images/serIcon3.png";
import icon4 from "../Assets/Images/serIcon4.png";
import icon5 from "../Assets/Images/serIcon5.png";

import readMoreIcon from "../Assets/Images/readMore.svg";
import groupImg1 from "../Assets/Images/Mask Group.png";
import groupImg2 from "../Assets/Images/Mask Group (1).png";
import coverImg from "../Assets/Images/cover.png";
import HireProcess from "../Features/HireProcess";

import indiSer from "../Assets/Images/IndiSer.png";
import { TestimonialV2 } from "../Components/TestimonialV2";

const IndividualService = () => {
  var bannerContent = {
    title1: "Penetration testing",
    title2: "Services Sample page",
    cardTitle:
      "Simplified advice and solutions. Engaging training. Industry approved.",
    cardPara:
      "Embed data protection, privacy, quality, security, and safety by design and default.",
    butnCtn1: "Request a proposal",
    butnCtn2: "",
  };
  return (
    <>
      <Banner bannerCnt={bannerContent} banImg={indiSer} />
      <section className="indi-service-sec sec-padding">
        <Container>
          <h2 className="sec-heading">Services</h2>
          <p className="sub-heading">
           consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
          <div className="indi-service-cards">
            <div className="indi-service-card">
              <img  loading="lazy"  width="" height=""   src={icon1} alt="icon1img" />
              <h5>Services</h5>
              <p>
               consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <a href="">
                <h6>
                  Learn More
                  <img  loading="lazy"  width="" height=""   alt="arrow" src={arrow} />
                </h6>
              </a>
            </div>
            <div className="indi-service-card">
              <img  loading="lazy"  width="" height=""   src={icon2} alt="icon2img" />
              <h5>Sourcing & Procurement</h5>
              <p>
               consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <a href="">
                <h6>
                  Learn More
                  <img  loading="lazy"  width="" height=""   alt="arrowimg" src={arrow} />
                </h6>
              </a>
            </div>
            <div className="indi-service-card">
              <img  loading="lazy"  width="" height=""   src={icon3} alt="icon3img" />
              <h5>Order Management</h5>
              <p>
               consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <a href="">
                <h6>
                  Learn More
                  <img  loading="lazy"  width="" height=""   alt="arrow1img" src={arrow} />
                </h6>
              </a>
            </div>
            <div className="indi-service-card">
              <img  loading="lazy"  width="" height=""   src={icon4} alt="icon4img" />
              <h5>Logistic Management</h5>
              <p>
               consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <a href="">
                <h6>
                  Learn More
                  <img  loading="lazy"  width="" height=""   alt="arrow-img" src={arrow} />
                </h6>
              </a>
            </div>
            <div className="indi-service-card">
              <img  loading="lazy"  width="" height=""   src={icon5} alt="icon5img" />
              <h5>Manufacturing Operations Support</h5>
              <p>
               consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <a href="">
                <h6>
                  Learn More
                  <img  loading="lazy"  width="" height=""   alt="arrow2img" src={arrow} />
                </h6>
              </a>
            </div>
          </div>
        </Container>
      </section>
      <HireProcess />
      <TestimonialV2 />
      <section className="indi-case-studies sec-padding">
        <Container>
          <div className="indi-case-cards">
            <div className="indi-case-card">
              <div className="ltd-case-description">
                <h3>IG-Smart Ltd's Case Studies</h3>
                <p>
                  Read our below client Case Studies and Testimonials to find
                  out why world renowned institutions and brands trust IG-Smart
                </p>
                <a href="">
                  <h6>
                    Read case studies
                    <img  loading="lazy"  width="" height=""   alt="readMoreIcon" src={readMoreIcon} />
                  </h6>
                </a>
              </div>
              <div className="ltd-case-img">
                <img  loading="lazy"  width="" height=""   src={groupImg1} alt="groupImg1" />
              </div>
            </div>
            <div className="indi-case-card case-card-second">
              <div className="ltd-case-img">
                <img  loading="lazy"  width="" height=""   className="ltd-img2" src={groupImg2} alt="groupImg2" />
              </div>
              <div className="ltd-case-description">
                <h3>
                  IG-Smart Ltd's Cyber Security, GDPR, Data Protection & Privacy
                  Services
                </h3>
                <p>
                  Request your Proposal or contact us to arrange to speak one
                  award winning Cyber Cyber Security Consultants, GDPR
                  Consultants, Data Protection Officers, or Cyber Essentials
                  Certification and ISO 27001 Certification experts.Request your
                  Proposal or contact us to arrange to speak one award winning
                  Cyber Cyber Security Consultants, GDPR Consultants, Data
                  Protection Officers, or Cyber Essentials Certification and ISO
                  27001 Certification experts.
                </p>
                <a href='javascript:void(0)'>
                  <h6>
                    Read case studies
                    <img  loading="lazy"  width="" height=""   alt="readMoreIcon" src={readMoreIcon} />
                  </h6>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="talk-to-expert-sec sec-padding">
        <Container>
          <div className="talk-to-expert-card">
            <div className="talk-to-expert-img">
              <img  loading="lazy"  width="" height=""   src={coverImg} alt="coverImg" />
            </div>
            <div className="talk-to-expert-description">
              <h2 className="sec-heading">Talk To An Expert</h2>
              <p className="sub-heading">
               consectetur adipiscing elit:
              </p>
              <div className="custom-list">
                <ul>
                  <li>The full course syllabus</li>
                  <li>Your questions answered, by phone or email</li>
                  <li>Helpful career advice with no obligations</li>
                </ul>
              </div>
              <p className="talk-to-expert-msg">
                Or if you're happy to get started, you can{" "}
                <a href="">
                  <span>enrol today.</span>
                </a>
              </p>
              <div className="btn_grp btn_center">
                <button className="btn-design">
                  Contact a CyberArk Representative
                </button>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Team title="Meet Our Digital Consulting Team" bgWhite={true} />
    </>
  );
};

export default IndividualService;
